import React, { useEffect, useState } from 'react'
import Footer from 'components/Footer/Footer'
import AudioPlayer from 'react-h5-audio-player'

import { listPendingApprovals } from 'controllers/AdminController'

import { getStorageAccessToken } from 'redux/actions/accountActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import ErrorLine from 'components/ErrorLine'
import { displayError } from 'util/screenUtils'
import DisplayHarmonizePost from 'vortex/DisplayHarmonizePost'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.js'
import { APPROVAL_STATUS } from 'util/postUtils'
import { APPROVAL_REASON } from 'util/postUtils'
import { approveMessage } from 'controllers/AdminController'
import { createRsaPublicKey } from 'util/encryptUtils'
import { encryptAudioUrl } from 'util/encryptUtils'

export default function ManageApprovals() {

  const [approvals, setApprovals] = useState()
  const [error, setError] = useState()
  const [approval, setApproval] = useState(APPROVAL_STATUS.NONE)
  const [approvalReason, setApprovalReason] = useState(APPROVAL_REASON.NONE)
  const accessToken = getStorageAccessToken()

  const doDecline = (reason) => {
    setApprovalReason(parseInt(reason))
  }
  const doApprove = async (id) => {

    try {
      //await approveAlbum(id, accessToken)
      setApproval(parseInt(id))
      //await fetchApprovals()
    } catch (error) {
      console.error('approval error', error)
    }
  }

  const updateApproval = async (msg) => {
    const { _id } = msg
    try {
      const response = await approveMessage(_id, approval, approvalReason, accessToken)
      if (response.status === 200) {
        setApprovals(null)
      } else {
        console.error('approval failed', response)
        displayError(response, setError)
      }
    } catch (error) {
      displayError(error, setError)
    }
  }

  const displayDeclinedOptions = () => {
    if (approval === APPROVAL_STATUS.DECLINED) {
      return (
        <div>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="gender" name="gender1" value={parseInt(approvalReason)} onChange={(evt) => doDecline(evt.target.value)}>
              <FormControlLabel value={APPROVAL_REASON.NONE} control={<Radio />} label="None" />
              <FormControlLabel value={APPROVAL_REASON.COPYRIGHT} control={<Radio />} label="Copyright" />
              <FormControlLabel value={APPROVAL_REASON.CONTENT} control={<Radio />} label="Content" />
            </RadioGroup>
          </FormControl>
        </div>
      )
    }
  }

  /**
   * Re RadioGroup "Value of the selected radio button. The DOM API casts this to a string."
   * See https://mui.com/material-ui/api/radio-group/#radio-group-prop-onChange
   * @returns 
   */
  const displayOptions = () => {
    return (
      <div>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="gender" name="gender1" value={parseInt(approval)} onChange={async (evt) => await doApprove(evt.target.value)}>
            <FormControlLabel value={APPROVAL_STATUS.NONE} control={<Radio />} label="None" />
            <FormControlLabel value={APPROVAL_STATUS.APPROVED} control={<Radio />} label="Approved" />
            <FormControlLabel value={APPROVAL_STATUS.DECLINED} control={<Radio />} label="Declined" />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  const canUpdate = () => {
    return approval === APPROVAL_STATUS.APPROVED || (approval === APPROVAL_STATUS.DECLINED && approvalReason !== APPROVAL_REASON.NONE)
  }

  const displayApproval = (approval, ix) => {
    const { username, name, source, url } = approval

    console.log(`displayApproval for ${source} ${url}`, approval)
    //const url = `${process.env.REACT_APP_CORE_SERVICE}media/audio/${encodeURIComponent(source)}/${sourceName}/${process.env.REACT_APP_API_KEY}`

    return (
      <div key={ix}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {username}
            <div style={{ display: 'flex', alignItems: 'center' }}>Approval:&nbsp;{displayOptions()}&nbsp;{displayDeclinedOptions()}</div>
          </div>
          <Button disabled={!canUpdate()}
            color="success"
            style={{ cursor: 'pointer' }}
            title={`Update approval for ${name}`}
            onClick={async () => { await updateApproval(approval) }}>
            Update
          </Button>
        </div>

        <AudioPlayer
          src={url}
          showFilledVolume
          onError={(error) => {
            console.error('Error playing audio', error)
            setError(`Unable to play `)
          }}
          style={{
            backgroundColor: 'white',
          }}
        />
        <DisplayHarmonizePost content={approval} fromAdmin={true} />
      </div>
    )
  }

  const getAudio = (msg) => {
    const { media } = msg
    for (const entry of media) {
      const { mimeType } = entry
      if (mimeType === 'audio/mpeg') {
        return entry
      }
    }
    return { source: undefined, name: '--' }
  }

  const getUrl = async (source, sourceName, rsaKeyPair) => {
    const { pub } = rsaKeyPair
    const url = await encryptAudioUrl(source, sourceName, pub)
    return url
  }

  const buildApprovalUrls = async (approvals) => {
    console.log('buildApprovalUrls', approvals)
    const rsaKeyPair = await createRsaPublicKey()
    return Promise.all(
      approvals.map(async (approval, ix) => {
        const { media } = approval
        const mediaEntry = media.find(a => a.mimeType === 'audio/mpeg')
        const { source, sourceName } = mediaEntry
        const url = await getUrl(source, sourceName, rsaKeyPair)
        return { ...approval, url }
      })
    )
  }

  const fetchApprovals = async () => {
    console.log('fetchApprovals')
    try {
      setError('')
      const approvals = await listPendingApprovals(0, 20, accessToken)
      const updatedApprovals = await buildApprovalUrls(approvals)
      setApprovals(updatedApprovals)
    } catch (e) {
      console.error('fetchApprovals', e)
      displayError(e, setError)
    }
  }

  useEffect(() => {
    document.title = 'Manage Approvals'
    if (!approvals) {
      fetchApprovals()
    }
  }, [approvals])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ margin: '1em' }}>

        <h3>Manage Approvals</h3>
        <ErrorLine error={error} />
        {approvals ? approvals.map((a, ix) => displayApproval(a, ix)) : null}
      </div>

      <Footer />
    </div>
  )

}
