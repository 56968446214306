import React from 'react'
import Footer from 'components/Footer/Footer.js'
import { ListRevenue } from 'components/transaction/ListRevenue'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { ListTransactions } from 'components/transaction/ListTransactions'
import { useEffect } from 'react'
import { useState } from 'react'

export default function Vault() {
  const [showTransactions, setShowTransactions] = useState(false)
  const displayRevenue = () => {
    if (!showTransactions) {
      return (
        <div>
          <h4 style={{ textAlign: 'center' }}>Vault: Revenue</h4>
          <div style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.75em', fontStyle: 'italic' }} onClick={() => setShowTransactions(true)}>Click here to show transactions</div>
          <ListRevenue />
        </div>
      )
    }
  }
  const displayTransactions = () => {
    if (showTransactions) {
      return (
        <div >
          <h4 style={{ textAlign: 'center' }}>Vault: Transactions</h4>
          <div style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.75em', fontStyle: 'italic' }} onClick={() => setShowTransactions(false)}>Click here to show revenue</div>
          <ListTransactions />
        </div>
      )
    }
  }
  useEffect(() => {
    document.title = 'Vault'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ margin: '1em' }}>
        {displayRevenue()}
        {displayTransactions()}
      </div>
      <Footer />
    </div>
  )
}
