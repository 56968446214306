import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { logWithTime } from 'util/screenUtils'
import { handleErrorsWithCode } from './AssetController'

export const ENGAGEMENT_TYPES = {
    AUDIO: 0,
    VIDEO: 1
}

/** Add the home and private rooms if they don't exist. In either case,
 * return both studios.
 * 
 * @param {*} accessToken 
 * @returns Array of both studios
 */
export const addArtistStudios = async (accessToken) => {
    logWithTime(`addArtistStudios`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/addArtistStudios`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((studios) => {
            return studios
        })
}

/**
 * 
 * @param {*} name 
 * @param {*} description 
 * @param {*} messageId         Id of initial Message added to Collection
 * @param {*} accessToken 
 * @param {*} accessType 
 * @returns 
 */
export const addCollection = async (name, description, messageId, accessToken, accessType = ACCESS_TYPES.PUBLIC) => {
    const collection = { name, description, messageId, accessType }
    logWithTime('addCollection', collection)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/add`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(collection),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

/**
 * 
 * @param {*} collectionId 
 * @param {*} messageId 
 * @param {*} accessToken 
 * @returns 
 */
export const addToCollection = async (collectionId, messageId, accessToken) => {
    logWithTime(`addToCollection ${collectionId}/${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/addMessage/${collectionId}/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

/**
 * Because public anonymous access is allowed, user is not required. Instead the
 * Api key is required. Note that this uses the Social Service because engagements
 * are attached to Messages.
 * @param {*} action    An ENGAGEMENT_TYPES value
 * @param {*} messageId Id of Message
 * @param {*} user      Id of User
 * @param {*} duration  Length of time in seconds at engagement
 * @returns 
 */
export const addEngagement = async (action, messageId, user, duration) => {
    const engagement = { action, messageId, user, duration }
    logWithTime('addEngagement', engagement)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/addEngagement`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(engagement),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

export const getChatLog = async (roomId, accessToken, start = 0, limit = 50) => {
    console.log(`getChatLog ${roomId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}chat/list/${roomId}/${start}/${limit}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((chatLog) => {
            return chatLog
        })

}

/**
 * 
 * @param {*} userId 
 * @returns 
 */
export const getCollections = async (userId) => {
    console.log(`getCollections ${userId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/list/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((collections) => {
            return collections
        })

}

export const getCreditPricing = async () => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/pricing`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((pricing) => {
            console.log('Got procing', pricing)
            return pricing
        })

}

/**
 * Add a Reaction to the specified Message for the signed-in User
 * @param {*} reaction {messageId, unifiedEmoji, playTime}
 * @param {*} accessToken 
 * @returns 
 */
export const addReaction = async (reaction, accessToken) => {
    logWithTime('addReaction', reaction)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/addReaction`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reaction),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

export const getReactions = async (messageId, accessToken) => {
    console.log(`getReactions ${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}reaction/list/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((reactions) => {
            return reactions
        })

}

/**
 * Hey you cannot start a function name with "use" or React thinks it's a hook.
 * @param {*} credit 
 * @param {*} accessToken 
 * @returns 
 */
export const applyCredit = async (credit, accessToken) => {
    logWithTime('applyCredit', credit)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/applyCredit`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credit),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

export const getAccruedCredits = async (accessToken) => {
    console.log(`getAccruedCredits`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/getAccruedCredits`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((accruedCredits) => {
            return accruedCredits
        })

}

/**
 * Get a fully-populated Collection including its referenced and populated Messages
 * (not just the messageIds)
 * @param {*} collectionId 
 * @param {*} accessToken 
 * @returns 
 */
export const getCollection = async (collectionId, accessToken) => {
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/get/${collectionId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((collection) => {
            return collection
        })
}

export const getSamplerSongs = async (start, count) => {
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/list/sampler/${start}/${count}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((songs) => {
            return songs
        })
}