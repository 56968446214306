import React from 'react'
import Header from 'components/Header/Header.js'

export default function StandardHeader(props) {
  const {
    initialColor = 'primary',
    scrollColor = 'primary',
    scrollHeight = 100,
    ...rest
  } = props

  return (
    <Header
      color={initialColor}
      fixed
      changeColorOnScroll={{
        height: scrollHeight,
        color: scrollColor,
      }}
      {...rest}
    />
  )
}
