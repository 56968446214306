import React from 'react'
import Footer from 'components/Footer/Footer.js'
import PricingSection from 'components/PricingSection'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { useEffect } from 'react'

export default function Pricing() {

  useEffect(() => {
    document.title = 'Pricing'
  }, [])
  return (
    <div>
      <HarmonizeHeader />

      <div style={{ paddingBottom: '5em' }}>

        <PricingSection />

      </div>
      <Footer />
    </div>
  )
}
