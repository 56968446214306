import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Footer from 'components/Footer/Footer'
import styles from 'assets/jss/material-kit-react/views/landingPage'

import classNames from 'classnames'

import { getStorageAccessToken } from 'redux/actions/accountActions'
import VortexHeader from 'components/Header/vortex/VortexHeader'
import { getThoughtCosts } from 'controllers/AdminController'

const useStyles = makeStyles(styles)
export default function ManageThoughts() {
  const [costs, setCosts] = useState([])

  const accessToken = getStorageAccessToken()
  const classes = useStyles()

  const displayCosts = () => {
    if (costs.length) {
      const { count, totalTokens } = costs[0]
      const chargedTokens = totalTokens / 1000
      const billableAmount = chargedTokens * .002
      const thoughtCost = chargedTokens * .01
      const margin = thoughtCost - billableAmount
      return (
        <>
          <div>Count: {count} Total Tokens: {totalTokens} </div>
          <div>Charged Tokens: {chargedTokens} Billable Amount: {billableAmount}</div>
          <div>Thought Cost: {thoughtCost} Margin: {margin}</div>
        </>
      )

    }
  }
  const fetchCosts = async () => {
    try {
      const costs = await getThoughtCosts(accessToken)
      setCosts(costs)
      console.log('Thought costs', costs)
    } catch (e) { }
  }

  useEffect(() => {
    fetchCosts()
  }, [])
  return (
    <div>
      <VortexHeader />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={styles.container}>
          <h3>Manage Thoughts</h3>
          {displayCosts()}
        </div>
      </div>
      <Footer />
    </div>
  )
}
