import { getStorageAccessToken } from 'redux/actions/accountActions'
import { setAccessToken } from 'redux/actions/accountActions'
import { setCurrentAsset } from 'redux/actions/assetActions'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { logWithTime } from 'util/screenUtils'
import { getAsset, handleErrors, handleErrorsWithCode } from './AssetController'

/** For a Public room there is no need to check access. 
 * 
 * Since we now go to a room even if there is no accessToken -- because
 * the sign in prompt occurs in the Vortex component -- we need to check access
 * after that sign in occurs.
 * 
 * @todo Check Room access after sign in
 * 
 *  I
 * @param {*} asset         Must contain the room
 * @param {*} accessToken 
 * @param {*} dispatch 
 * @param {*} onError 
 */
const checkRoom = async (asset, accessToken, dispatch, onError) => {
    try {
        const { room } = asset
        const { accessType, name } = room
        logWithTime(`checkRoom ${name} public ${accessType}`)
        if (!accessToken) {
            return gotoRoom(asset, accessType, dispatch)
        } else {
            console.log(`checkRoom`, asset)
            const response = await checkRoomAccess(asset, accessToken)
            const { status } = response
            switch (status) {
                case 200:
                    const access = await response.json()
                    console.log('checkRoom access', access)
                    const { accessType } = access
                    return gotoRoom(asset, accessType, dispatch)

                case 401:
                    console.error('checkRoom Unauthorized')
                    dispatch(setAccessToken(null))
                    onError({ status, message: 'Unauthorized' })
                    break

                case 403:
                    //This is only returned if the room is private and this user is not the owner.
                    onError({ status, message: 'Private room' })
                    break
                default:
                    console.log('response', response)
                    if (response.statusText) {
                        onError({ status, message: response.statusText })
                    } else {
                        const error = await response.json()
                        onError({ status, message: error.message })
                    }
                    break
            }
        }
    } catch (error) {
        console.error(error)
        onError(error.message)
    }
    return null
}

/**
 * 
 * @param {*} asset 
 * @param {*} accessType 
 * @param {*} dispatch 
 * @returns 
 */
const gotoRoom = (asset, accessType, dispatch) => {
    dispatch(setCurrentAsset({ ...asset, accessType }))
    const { _id, room } = asset
    console.log(`gotoRoom room `, room)
    dispatch(setCurrentRoom(room))
    return ({ ...room, assetId: _id })
}

/**
 * Get the requested asset then call checkRoom to go to the room. If the Asset does not exist go to
 * the home page. If there is an error call onError.
 * @param {*} title 
 * @param {*} handle 
 * @param {*} username 
 * @param {*} accessToken 
 * @param {*} history 
 * @param {*} dispatch 
 * @param {*} onError 
 * @returns 
 */
export const getAndConnectRoom = async (title, handle, username, accessToken, history, dispatch, onError) => {
    console.log(`***getAndConnectRoom ${title} ${handle} ${username}`)
    try {
        const roomAsset = await getAsset({ title, handle, username })
        console.log(`...getAndConnectRoom received`, roomAsset)
        if (roomAsset) {
            return checkRoom(roomAsset, accessToken, dispatch, onError)
        } else {
            history.replace('/')
        }
    } catch (exc) {
        onError(exc)
    }
    return null
}

export const addEmbed = async (messageId, embed, accessToken) => {
    const embedParams = { messageId, embed }
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/addEmbed`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(embedParams),
    })
        .then(handleErrors)
        .then(() => {
            console.log('...added embed')
            return true
        })
}

/**
 * Add a Message directly to Social Service
 * @param {*} message 
 * @param {*} accessToken 
 * @returns 
 */
export const addMediaMessage = async (message, accessToken) => {
    logWithTime('addMediaMessage', message)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/addMessage`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
    })
        .then(handleErrors)
        .then(() => {
            return true
        })
}

/**
 * 
 * @param {*} assetParams  {assetId}
 * @param {*} accessToken 
 * @returns the fetch response
 */
export const checkRoomAccess = async (assetParams, accessToken) => {
    console.log(`checkRoomAccess`, assetParams)
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/checkRoomAccess`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(assetParams),
    })
}

/**
 * Upload a JPEG, MP3, MP4 or PDF. No other type is currently allowed.
 * Note that this uses the Core Service for uploading audio and video and the Social Service for uploading
 * everything else. Why?
 *
 * @param {*} fileData
 * @param {*} params        {roomId,username,messageId} messageId is used so Core Service can update Message media after long HLS build
 * @param {*} accessToken
 * @param {*} isImage       If true the Social Service is used 
 * @returns
 */
export const uploadMedia = async (fileData, params, accessToken, isImage) => {
    console.log(`uploadMedia isImage ${isImage} fileData`, fileData)
    const formData = new FormData()
    formData.append('media', fileData)
    formData.append('params', JSON.stringify(params))
    const url = isImage ? `${process.env.REACT_APP_SOCIAL_SERVICE}upload/media` : `${process.env.REACT_APP_CORE_SERVICE}upload/media`
    return fetch(url,
        {
            method: 'POST',
            headers: {
                'x-access-token': accessToken,
            },
            body: formData,
        })
        .then(handleErrors)
        .then((res) => res.json())
        .then((media) => {
            console.log('Added mediaSchema', media)
            return media
        })
}

/**
 * @param {*} roomParams 
 * @param {*} accessToken 
 * @returns 
 */
export const addRoom = async (roomParams, accessToken) => {

    return fetch(`${process.env.REACT_APP_CORE_SERVICE}vortex/addRoom`, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(roomParams),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((room) => {
            console.log('Added room', room)
            return room
        })
}

/**
 * 
 * @param {*} action 
 * @param {*} count 
 * @param {*} reference 
 * @param {*} accessToken 
 * @returns 
 *
export const addThought = async (action, count, reference, accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/addThought`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action, count, reference }),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((thoughtResponse) => {
            return thoughtResponse
        })
}
*/

/*
export const deleteMessage = async (messageId, accessToken) => {
    console.log(`deleteMessage ${messageId} `)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/delete/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then(() => {
            console.log('...deleted')
            return true
        })
}


export const deleteMessageMedia = async (messageId, mediaIndex, accessToken) => {
    console.log(`deleteMessageMedia ${messageId} ${mediaIndex}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/deleteMedia/${messageId}/${mediaIndex}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((updatedMessage) => {
            console.log('...deleted, updated Message', updatedMessage)
            return updatedMessage
        })
}
*/

/**
 * 
 * @param {*} videoPath     The relative root directory for the media that will be deleted
 * @param {*} accessToken 
 * @returns 
 */
export const deleteMessageHLS = async (videoPath, accessToken) => {
    console.log(`deleteMessageHLS ${videoPath} `)
    const url = `${process.env.REACT_APP_CORE_SERVICE}media/video/delete/allMedia`
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': accessToken
        },
        body: JSON.stringify({ videoPath }),
    })
        .then(handleErrors)
        .then(() => {
            return true
        })
}

/**
 * This is an UNAUTHENTICATED call protected by the REACT_APP_API_KEY that must match the 
 * one configured on the Social Service.
 * @param {*} messageId 
 * @returns 
 */
export const getMessage = async (messageId) => {
    //console.log(`getMessage ${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/get/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((message) => {
            return message
        })
}

/**
 * 
 * @param {*} roomId 
 * @param {*} start 
 * @param {*} count 
 * @param {*} category 
 * @returns 
 */
export const getMessages = async (roomId, start, count, category = 'Audio') => {
    console.log(`getMessages ${start} for ${count}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}messages/get/${roomId}/${start}/${count}/${category}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((messages) => {
            return messages
        })
}

/**
 * Requires Api key.
 * 
 * @param {*} roomParams 
 * @returns 
 */
export const getRoom = async (roomParams) => {
    console.log(`getRoom`, roomParams)
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/getRoom`
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(roomParams),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((room) => {
            console.log('got room', room)
            return room
        })
}

export const incrementRoomViews = async (roomId) => {
    console.log(`incrementRoomViews ${roomId}`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/incrementRoomViews/${roomId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((room) => {
            return room
        })
}

/**
 * Update the Core server Room
 * @param {*} room
 * @param {*} accessToken 
 * @returns The updated room
 */
export const updateRoom = async (room, accessToken) => {
    return fetch(`${process.env.REACT_APP_CORE_SERVICE}vortex/updateRoom`, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(room),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((updatedRoom) => {
            console.log('Updated room', updatedRoom)
            return updatedRoom
        })
}

/* NO MO
export const addLike = async (messageId, accessToken) => {
    console.log(`addLike to ${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/addLike/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrors)
        .then(() => {
            console.log('...added like')
            return true
        })
}


export const deleteLike = async (messageId, accessToken) => {
    console.log(`deleteLike from ${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/deleteLike/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrors)
        .then(() => {
            console.log('...deleted like')
            return true
        })
}
*/

export const getLikes = async (messageId, accessToken) => {
    console.log(`getLikes ${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/likes/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((likes) => {
            return likes
        })
}

/**
 * 
 * @param {*} ownerPaysThoughts 
 * @param {*} sessionRoomId 
 * @returns 
 */
export const getCurrentThoughtBalance = async (ownerPaysThoughts, sessionRoomId) => {
    const thoughtBalance = ownerPaysThoughts
        ? await getThoughtBalanceForRoom(sessionRoomId, getStorageAccessToken())
        : await getAvailableThoughts(getStorageAccessToken())
    console.log('getCurrentThoughtBalance', thoughtBalance)
    return thoughtBalance.availableThoughts
        ? thoughtBalance.availableThoughts
        : 0
}

export const getAvailableThoughts = async (accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/getThoughtBalance/0`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((availableThoughts) => {
            return availableThoughts
        })
}

/**
 * Call this from AIChat in a Room to determine if the Thoughts are paid by the Owner
 * and if so how may remain. The signed in user must be the Owner
 * @param {*} roomId 
 * @param {*} accessToken 
 * @returns {ownerPaysThoughts,availableThoughts}
 */
export const getThoughtBalanceForRoom = async (roomId, accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/getThoughtBalanceForRoom/${roomId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((availableThoughtsForRoom) => {
            return availableThoughtsForRoom
        })
}

/**
 * This does not return deleted Thoughts
 * @param {*} sessionId 
 * @param {*} accessToken 
 * @returns 
 */
export const getThoughtsForSession = async (sessionId, accessToken) => {
    console.log(`getThoughtsForSession ${sessionId}`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/getThoughtsForSession/${sessionId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((thoughts) => {
            return thoughts
        })
}

/**
 * 
 * @param {*} session 
 * @param {*} accessToken 
 * @returns 
 */
export const updateSession = async (session, accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/updateSession`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(session),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((updatedSession) => {
            return updatedSession
        })
}

/**
 * 
 * @param {*} query
 * @param {*} createdAt 
 * @param {*} accessToken 
 * @returns 
 */
export const summarize = async (query, createdAt, accessToken) => {
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}ai/summarizeSubscribedRooms`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, createdAt }),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((summary) => {
            logWithTime('Received summary', summary)
            return summary
        })
}