import { uploadMedia } from 'controllers/VortexController'
import { MEDIA_TYPES } from './postUtils'

const getInt = (value) => {
  if (value.length) {
    try {
      const n = parseInt(value)
      if (Number.isNaN(n)) {
        return 0
      }
      return n
    } catch { }
  }
  return 0
}

export const getAspectRatio = (strDimensions) => {
  const { width, height } = parseDimensions(strDimensions)
  if (width && height) {
    return width / height
  }
  return 1
}

/**
 * Create dimensions consistent with Lightroom
 * @param {*} width
 * @param {*} height
 * @returns Dimensions a la Lightroom: [w] x [h]
 */
export const createDimensions = (width, height) => {
  return `${width} x ${height}`
}

export const getMediaAudio = (media) => {
  let mediaAudio
  for (const m of media) {
    const { mimeType, source, sourceName } = m
    if (MEDIA_TYPES.AUDIO.includes(mimeType)) {
      mediaAudio = { name: sourceName, source, mimeType }
      break
    }

  }
  return mediaAudio
}

/**
 * @todo Updating the Message media is creating an empty 0th array element
 * @param {*} media Array of Message media
 * @param {*} type  'display' or 'thumbnail'
 * @returns a mediaImage object with name, source, mimeType, imageType or null if there is no imageType in the media
 */
const getMediaImage = (media, type) => {
  let mediaImage
  for (const m of media) {
    if (m) {
      //console.log(`getMediaImage type ${type}`, m)
      const { mimeType, imageType, mediaSource, source, sourceName } = m
      if (MEDIA_TYPES.IMAGE.includes(mimeType)) {
        if (imageType && imageType === type) {
          const sourceDisplayName = imageType === 'thumbnail' ? sourceName.replace('t_', '') : sourceName
          mediaImage = { name: sourceDisplayName, source: mediaSource ? mediaSource : source, mimeType, imageType }
          break
        } else {
          mediaImage = { name: sourceName, source: mediaSource ? mediaSource : source, mimeType }
        }
      }
    }

  }
  return mediaImage
}

export const getMediaThumbnail = (media) => {
  return getMediaImage(media, 'thumbnail')
}

/**
 * 
 * @param {*} media 
 * @returns 
 */
export const getMediaDisplay = (media) => {
  return getMediaImage(media, 'display')
}

/**
 *
 * @param {*} strDimensions Dimensions a la Lightroom: [w] x [h]
 * @returns {width,height}
 */
export const parseDimensions = (strDimensions) => {
  if (strDimensions && strDimensions.length) {
    const ix = strDimensions.indexOf(' x ')
    if (ix !== -1) {
      const w = strDimensions.substring(0, ix)
      const h = strDimensions.substring(ix + 3, strDimensions.length)
      return { width: getInt(w), height: getInt(h) }
    }
  }
  return { width: 0, height: 0 }
}

/**
 *
 * @param {*} strDimensions Dimensions a la Lightroom: [w] x [h]
 * @returns True if width<height
 */
export const isPortrait = (strDimensions) => {
  const dim = parseDimensions(strDimensions)
  return dim.width < dim.height
}

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

/**
 * Attempt to get the width and height of the specified file. If it works, update
 * the passed params with {maxAvailWidth,maxAvailHeight,dimensions} where the max
 * values are the actual file specs and dimensions isa la Lightroom: [w] x [h]
 * @param {*} file
 * @param {*} params
 * @returns Possible updated params
 */
export const updateParmsWithImageDimensions = async (file, params) => {
  const dims = await getImageDimensions(file)
  let updatedParams = params
  if (dims) {
    const { width: maxAvailWidth, height: maxAvailHeight } = dims
    const dimensions = createDimensions(maxAvailWidth, maxAvailHeight)
    updatedParams = { ...params, dimensions, maxAvailWidth, maxAvailHeight }
  }
  return updatedParams
}

/**
 * Get the width and height of the specified image.
 * @param {*} file Name of image file
 * @returns {width,height}
 */
const getImageDimensions = async (file) => {
  try {
    const dataUrl = await readFileAsync(file)
    const image = new Image()
    image.src = dataUrl
    await image.decode()
    const width = image.naturalWidth
    const height = image.naturalHeight
    return { width, height }
  } catch (error) {
    console.error(`Unable to getFileDimensions ${file}`, error)
  }
  return null
}

/**
 *
 * @param {*} file
 * @param {*} maxWidth
 * @param {*} maxHeight
 * @param {*} quality
 * @returns
 */
export const resizeAsync = async (file, maxWidth, maxHeight, quality) => {
  try {
    const dataUrl = await readFileAsync(file)
    const image = new Image()
    image.src = dataUrl
    await image.decode()
    const resizedData = resizeImage(image, maxWidth, maxHeight, quality)
    console.log('resizedData', resizedData)
    return resizedData
  } catch (error) {
    console.error(`Unable to resize ${file}`, error)
  }
  return null
}
/**
 * Read a File into an ArrayBuffer
 * @param {*} file
 * @param {*} success     Callback with data
 * @param {*} failure    Callback with reader.error
 */
export const readAsArrayBuffer = (file, success, failure) => {
  const reader = new FileReader()
  reader.onError = function (event) {
    failure(reader.error)
  }
  reader.onload = function (event) {
    success(event.target.result)
  }
  reader.readAsArrayBuffer(file)
}

export const readAsBlob = (file, success, failure) => {
  const reader = new FileReader()
  reader.onError = function (event) {
    failure(reader.error)
  }
  reader.onload = function (event) {
    success(event.target.result)
  }
  reader.readAsBinaryString(file)
}

/**Read the File data, then resize it. Return either base64 data, or a Blob.
 * This method uses readAsDataUrl, then sets that as the src in an Image passed to
 * resizeImage.
 * @param {*} file      File instance to resize
 * @param {*} maxWidth
 * @param {*} maxHeight
 * @param {*} quality   A number between 0 and 1
 * @param {*} callback  If toBlob, receives Blob; otherwise receives base64 data encoded
 *                      with a "data: URL" {file,dataUrl}
 * @param {*} toBlob    If true call resizeImage with toBlob and return {file,dateUrl:resizedData}
 */
export const resize = (
  file,
  maxWidth,
  maxHeight,
  quality,
  callback,
  toBlob
) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function (event) {
    const dataUrl = event.target.result

    const image = new Image()
    image.src = dataUrl
    image.onload = function () {
      if (toBlob) {
        resizeImage(image, maxWidth, maxHeight, quality, callback)
      } else {
        const resizedData = resizeImage(image, maxWidth, maxHeight, quality)
        callback({ file, dataUrl: resizedData })
      }
    }
  }
}

/**
 * Because this calls resize, the returned data is base64 encoded.
 * @param {*} file
 * @param {*} envImageSize  Environment string defining uploaded image max dimension
 * @param {*} success       Called back with dataUrl
 * @param {*} failure
 */
export const resizeForUpload = (file, envImageSize, success, failure) => {
  try {
    const uploadSize = parseInt(envImageSize)
    const quality = parseFloat(process.env.REACT_APP_UPLOAD_IMAGE_QUALITY)
    resize(file, uploadSize, uploadSize, quality, ({ dataUrl }) =>
      success(dataUrl)
    )
  } catch (error) {
    console.error('Error handling image', error)
    failure(error)
  }
}

/**
 * This resizes by drawing image onto a 2d canvas. Note that this always creates a JPEG.
 * @param {*} image
 * @param {*} maxWidth    Maximum width of resized image
 * @param {*} maxHeight   Maximum height of resized image
 * @param {*} quality     A number between 0 and 1
 * @param {*} toBlob      If defined creates a Blob and passes it to this method
 * @returns either a base64-encoded URL
 * or a Blob for the resized data.
 */
export const resizeImage = (
  image,
  maxWidth,
  maxHeight,
  quality,
  blobCallback
) => {
  const canvas = document.createElement('canvas')

  let width = image.width
  let height = image.height

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width)
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height)
      height = maxHeight
    }
  }

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, 0, 0, width, height)
  if (blobCallback) {
    canvas.toBlob(
      (data) => {
        console.log(`Created blob length ${data.length} width ${width} height ${height}`)
        blobCallback(data)
      },
      'image/jpeg',
      quality
    )
  } else {
    return canvas.toDataURL('image/jpeg', quality)
  }
}
