import React from 'react'

import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { DataUsage } from '@material-ui/icons'
export default function PricingSection() {

  const displayPricing = () => {

    return (
      <>
        <p><i>Credits</i> are what you buy to use Harmonize. A standard credit is good for 1 minute of CD-quality audio streaming*.</p>
        <p>Your current Credit balance is always displayed in the audio player, inside this icon:</p>
        <DataUsage color='primary' style={{ fontSize: '2em', display: 'flex', width: '100%', justifyContent: 'center' }} />
        <p>and is updated as you play music.</p>
        <p>Your credits do not expire. You can always purchase more credits at <Link to='/BuyCredits'>this page</Link></p>

        <h5>Why Credits?</h5>
        The guiding principles behind Harmonize are:
        <ul>
          <li>We want artists to get paid in a clear and transparent fashion.</li>
          <li>We want listeners to only pay for what they use</li>
          <li>The world does not need any more ads</li>
        </ul>
        <p>We accomplish these goals with Harmonize credits. A fixed portion of every credit used to stream audio goes directly to the recording artist.</p>
        <hr />
        <p>*Artists may choose to charge more than 1 credit per minute for streaming their music. If so, this will be indicated at the top of their studio
          in which this pricing applies.</p>
      </>
    )

  }

  return (
    <>
      <Container maxWidth='md' component='main'>

        <h4 style={{ color: 'black', textAlign: 'center', borderBottom: '1px solid black' }}>Pricing</h4>

        {displayPricing()}

      </Container>
    </>
  )
}
