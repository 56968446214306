import React from 'react'
import Footer from 'components/Footer/Footer.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { Container } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

export default function About() {

  const displayAbout = () => {
    return (
      <div>
        Harmonize optimizes revenue and relationships for new and emerging artists.
        <p>
          We provide a clean and direct relation between streaming costs and streaming payments.
          Harmonize allows artists to set the pricing for their work.</p>
        <p>We also provide multiple mechanisms for artists to engage more directly with their fans,
          especially while songs are playing.</p>

        <h4>The Harmonize Business Model</h4>
        <p>Artist earn money at the rate of ${process.env.REACT_APP_ARTIST_INTERVAL_RATE}&nbsp;
          for every {process.env.REACT_APP_ACCRUE_CREDIT_TIME} seconds one of your songs is played.
          You can view your current earned credits at any time on your&nbsp;
          <Link to='/Vault'>Vault page</Link>. For more information visit our <Link to='/help/FAQ'>FAQ</Link>.</p>
        <p><b>Harmonize artists must own 100% of the rights - both song writing and publishing - to their music.</b>
          Harmonize pays all streaming revenue directly to the account holder who uploads the song.
          There is no mechanism for splitting these payments among multiple parties whether
          they are performing musicians, song writers or publishers.
        </p>

        <p>Harmonize does not pay royalties to any other organization, including performance and mechanical royalties and therefore <b>cover songs must not be uploaded</b>.</p>

        <p>Artists may publish their songs through other platforms that utilize different payment mechanisms.</p>
        <p>
          For our complete Terms of Service, <Link to='/TermsOfService'>click here</Link>.
        </p>
      </div>
    )
  }
  useEffect(() => {
    document.title = 'About Harmonize'
  }, [])
  return (
    <div>
      <HarmonizeHeader />

      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>

          <h4 style={{ color: 'black', textAlign: 'center', borderBottom: '1px solid black' }}>About</h4>

          {displayAbout()}

        </Container>

      </div>
      <Footer />
    </div>

  )
}
