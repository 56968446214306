import React from 'react'

import Parallax from '../../Parallax/Parallax.js'

import harmony from '../../../images/harmony.jpg'
import StandardHeader from '../StandardHeader'

export default function VortexHeader({ background = harmony }) {
  return (
    <>
      <StandardHeader initialColor={'transparent'} scrollHeight={20} />
      <Parallax smallest filter image={background} />
    </>
  )
}
