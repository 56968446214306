import { MusicNoteOutlined, PictureAsPdfTwoTone, VideocamTwoTone, InsertDriveFileOutlined } from '@material-ui/icons'
import ErrorLine from 'components/ErrorLine'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useDispatch, useSelector } from 'react-redux'
import { setSongMediaIcons } from 'redux/actions/songActions'
import { isImageMimeType } from 'util/fileUtils'
import { displayError } from 'util/screenUtils'
export default function MediaIcons({ mediaIcons, imageClicked }) {
    const [error, setError] = useState()
    const [imageSrc, setImageSrc] = useState()

    const dispatch = useDispatch()

    const getIcon = (imageIcon, ix) => {
        const { name, dataUrl, mimeType } = imageIcon
        if (mimeType === 'audio/mpeg') {
            return (
                <div key={ix}>
                    <MusicNoteOutlined style={{ fontSize: '3em' }} color='secondary' />
                </div>
            )
        } else if (isImageMimeType(mimeType)) {
            return (
                <img
                    key={ix}
                    src={imageSrc ? imageSrc : dataUrl}
                    alt={name}
                    style={{
                        width: '120px',
                        display: 'flex',
                        margin: 'auto',
                    }}
                />
            )
        } else if (mimeType === 'application/pdf') {
            return (
                <div key={ix}>
                    <PictureAsPdfTwoTone fontSize='large' color='secondary' />
                </div>
            )
        }
        else if (mimeType === 'video/mp4') {
            return (
                <div key={ix}>
                    <VideocamTwoTone fontSize='large' color='secondary' />
                </div>
            )
        }
        else {
            return (
                <div key={ix}>
                    <InsertDriveFileOutlined fontSize='large' color='secondary' />
                </div>
            )
        }
    }

    const updateIcons = (ix) => {
        mediaIcons().splice(ix, 1)
        dispatch(setSongMediaIcons([...mediaIcons()]))
    }

    const displayIcon = (imageIcon, ix) => {
        return (
            <div
                key={ix}
                onClick={() => (imageClicked ? imageClicked(ix) : updateIcons(ix)
                )}
                title={'Click to remove'}
                style={{
                    cursor: 'pointer',
                    border: '1px solid blue',
                    borderRadius: '0.5em',
                    padding: '0.25em',
                    margin: '0.5em',
                    width: '150px',
                    textAlign: 'center',
                }}
            >
                {getIcon(imageIcon, ix)}
                <div
                    style={{ fontSize: '0.75em', textAlign: 'center', lineHeight: '1em', wordBreak: 'break-all' }}
                >
                    {imageIcon.name}
                </div>
            </div>
        )
    }

    const displayIcons = () => {
        //console.log('MediaIcons icons', mediaIcons())
        return mediaIcons().map((f, ix) => displayIcon(f, ix))
    }

    const getIconMedia = async (mi) => {
        const { source, mimeType } = mi

        if (source) {
            try {
                switch (mimeType) {
                    case 'image/png':
                    case 'image/jpeg':

                        const data = await downloadCoreFile(source, 'image')
                        setImageSrc(URL.createObjectURL(data))

                    default: break
                }
            } catch (error) {
                console.error(error)
                displayError(`Unable to display image ${source}`, setError)
            }
        }
    }

    const loadMedia = () => {
        setError('')
        console.log('loadMedia', mediaIcons())
        if (mediaIcons()) {
            Promise.all(
                mediaIcons().map(async (mi) => getIconMedia(mi))
            )
        }
    }

    /*
    const loadMedia = () => {
        setError('')
        console.log('loadMedia', mediaIcons())
        if (mediaIcons()) {
            mediaIcons().forEach(async mi => {
                const { source, sourceName, mimeType } = mi
                console.log(`loadMedia ${source} ${mimeType}`)
                if (source) {
                    try {
                        switch (mimeType) {
                            case 'image/png':
                            case 'image/jpeg':
                                const data = await downloadCoreFile(source, 'image')
                                setImageSrc(URL.createObjectURL(data))
                            default: break
                        }
                    } catch (error) {
                        console.error(error)
                        displayError(`Unable to display image ${sourceName}`, setError)
                    }
                }
            })
        }
    }
    */

    useEffect(() => {
        loadMedia()
    }, [mediaIcons()])
    if (mediaIcons().length) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ErrorLine error={error} />
                <ScrollMenu style={{ display: 'flex', alignItems: 'center' }}>{displayIcons()}</ScrollMenu>
            </div>
        )
    } else { return null }
}