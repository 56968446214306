import React, { useState } from 'react'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'

import imagesStyles from 'assets/jss/material-kit-react/imagesStyles.js'

import { cardTitle } from 'assets/jss/material-kit-react.js'
import { isNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { isVeryNarrow } from 'util/screenUtils'

const styles = {
  ...imagesStyles,
  cardTitle,
  card: {
    borderRadius: '2em', color: 'white',
    backgroundColor: 'rgba(61, 64, 212,0.8)',
    width: '35vw',
    display: 'flex',
    justifyContent: 'center'
  },
  hovered: {
    margin: 'auto',
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  notHovered: {
    margin: 'auto',
  },
}

export default function HomeCard({ title, description, summary, action, icon, link, content, image }) {
  const narrow = isNarrow(useMediaQuery)
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [hovered, setHovered] = useState(false)
  const history = useHistory()

  const displayTextCard = () => {
    return (

      <Card
        className='plain'
        title={title}
        style={{ ...styles.card, ...hovered ? styles.hovered : styles.notHovered }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        {icon ? <div style={{ flex: '25%' }}>{icon}</div> : null}

        <CardBody
          style={{
            display: 'flex',
            textAlign: 'center',
            fontWeight: hovered ? 'bold' : 'normal',
            color: hovered ? 'gold' : 'white',
            alignItems: "center",
            justifyContent: 'center',
            fontSize: narrow ? '0.75em' : '1em',
            padding: '10px'
          }}
        >
          {content ? content() :
            <div>
              <p style={{ fontWeight: 'bold', fontSize: veryNarrow ? '1em' : '1.5em', borderBottom: '1px solid white' }}>{title}</p>
              {image ? <div style={{ textAlign: 'left' }}><div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>

                <img src={image} alt={title} style={{ width: '50%', float: 'left', paddingRight: '0.5em' }} />
                {description}{veryNarrow ? null : summary}
              </div>
                {veryNarrow ? summary : null}</div>
                : { description }}
            </div>
          }
        </CardBody>
      </Card>

    )
  }

  if (action) {
    return (<div style={{ cursor: 'pointer' }} onClick={action}>
      {displayTextCard()}
    </div>)
  } else {
    return (
      <div onClick={() => {
        history.push(link)
        ReactGA.event({ category: 'click', action: 'home_card', label: title })
      }}>

        {displayTextCard()}
      </div>)

  }
}
