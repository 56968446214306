import { combineReducers } from 'redux'
import accountReducer from './accountReducer'
import artistReducer from './artistReducer'
import assetReducer from './assetReducer'
import chatReducer from './chatReducer'
import helpReducer from './helpReducer'
import harmonizeReducer from './harmonizeReducer'
import hostTypeReducer from './hostTypeReducer'
import messagesReducer from './messagesReducer'
import purchaseReducer from './purchaseReducer'
import settingsReducer from './settingsReducer'
import songReducer from './songReducer'

export default combineReducers({
  account: accountReducer,
  artist: artistReducer,
  asset: assetReducer,
  chat: chatReducer,
  help: helpReducer,
  harmonize: harmonizeReducer,
  hostType: hostTypeReducer,
  messages: messagesReducer,
  purchase: purchaseReducer,
  settings: settingsReducer,
  song: songReducer
})
