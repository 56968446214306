import React from 'react'
import Footer from 'components/Footer/Footer'

import styles from 'assets/jss/material-kit-react/views/landingPage'
import classNames from 'classnames'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setCurrentAssets } from 'redux/actions/assetActions'

import { isVeryNarrow } from 'util/screenUtils'
import CreateVortexRoom from 'components/vortex/CreateVortexRoom'
import VortexHeader from 'components/Header/vortex/VortexHeader'

const useStyles = makeStyles(styles)
export default function CreateRoom() {
  const veryNarrow = isVeryNarrow(useMediaQuery)

  const dispatch = useDispatch()
  const classes = useStyles()

  const displayMain = () => {
    return (
      <div>
        <VortexHeader noSlogan />
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={
            veryNarrow
              ? { marginLeft: '1em', marginRight: '1em' }
              : { marginLeft: '2em', marginRight: '2em' }
          }
        >
          <div
            className={classes.container}
            style={{ paddingLeft: '1vw', paddingRight: '1vw' }}
          >
            <CreateVortexRoom />

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  useEffect(() => {
    dispatch(setCurrentAssets([]))
  }, [])

  if (process.env.REACT_APP_MODE === 'development') {
    return displayMain()
  } else {
    return <div onContextMenu={(e) => e.preventDefault()}>{displayMain()}</div>
  }
}
