import CookieConsent from 'react-cookie-consent'

export default function AnnoyingConsent() {
  const brandName = process.env.REACT_APP_NAME
  return (
    <CookieConsent style={{ paddingBottom: '5em' }}>
      The {brandName} website uses local data storage (often called "cookies")
      in your browser to provide personalization, as well as support for purchases
      (using Stripe) and usage analysis (using Google Analytics). {brandName} does not track you
      across third-party websites, or provide any {brandName} data to
      third-party organizations.
    </CookieConsent>
  )
}
