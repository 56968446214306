import { Snackbar, useMediaQuery } from '@material-ui/core'
import { io } from 'socket.io-client'
import roundBrain from 'images/round-brain.png'
import ErrorLine from 'components/ErrorLine'
import { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { useDispatch, useSelector } from 'react-redux'
import { setChatMessages } from 'redux/actions/chatActions'
import parse from 'html-react-parser'
import {
  getMessage,
} from 'controllers/VortexController'
import {
  AddBox,
  Cancel,
  DateRangeTwoTone,
  DeleteForeverTwoTone,
  FileCopyTwoTone,
  HelpOutline,
  Label,
  LabelOff,
  SpeakerNotes,
  TimerOffTwoTone
} from '@material-ui/icons'
import DisplayHarmonizePost from './DisplayHarmonizePost'
import { Alert } from '@mui/material'
import { isVeryNarrow } from 'util/screenUtils'
import { logWithTime } from 'util/screenUtils'
import { displayError } from 'util/screenUtils'
import { getSession } from 'controllers/AIController'
import { setAccessToken } from 'redux/actions/accountActions'
import { setChatSessionId } from 'redux/actions/chatActions'
import { addChatMessage } from 'redux/actions/chatActions'
import DisplaySessions from 'components/vortex/DisplaySessions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { setCurrentCollaborator } from 'redux/actions/chatActions'
import { addSession } from 'controllers/AIController'
import { setWaitingForHost } from 'redux/actions/chatActions'
import InputAI from 'components/vortex/InputAI'
import { setChatRoomId } from 'redux/actions/chatActions'
import moment from 'moment'
import { AI_TYPE } from 'controllers/AIController'
import { setShowAi } from 'redux/actions/harmonizeActions'
import { deleteSessionThought } from 'controllers/AIController'
import { deleteSession } from 'controllers/AIController'
import { setShowAiWelcome } from 'redux/actions/helpActions'
import BlurDialog from 'components/utility/BlurDialog'
import harmonySmall from '../images/harmony-small.jpg'
import Explain from 'components/Explain'
import TimerProgress from 'components/mint/TimerProgress'

export const MODALITIES = {
  NONE: 0,
  CHESS: 1,
  MUSIC: 2
}

export const aiChatStyles = {
  label: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontStyle: 'italic',
    fontSize: '0.75em',
  },
  answerLine: {
    borderBottom: '0.5px solid black',
  },
  button: {
    display: 'flex',
    paddingRight: '1em',
    cursor: 'pointer',
    fontSize: '1.5em'
  },
  buttonAwesome: {
    display: 'flex',
    paddingRight: '1em',
    cursor: 'pointer',
    fontSize: '1.25em'
  },
  chatDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '0.25em',
    paddingBottom: '0.25em',
    width: '100%'
  },
  chatDivNarrow: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0.5'
  },
  collaboratorsDiv: {
    flex: '0.4',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '0.5em',
    height: '70vh',
  },
  queryLine: {
    borderBottom: '0.5px dashed gray',
    backgroundColor: 'azure',
  },

  queryInputLine: {
    position: 'fixed',
    bottom: '0',

    backgroundColor: 'white'
  },
  queryInputLineNarrow: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: 'white',
    border: '0.5px solid gray',
    display: 'flex',
  },
  sendButton: { width: '90vw' },
  sendButtonNarrow: { width: '80vw' },
  sessionLine: {
    borderBottom: '0.5px dashed gray',
    cursor: 'pointer',
  },
}

let socket
/**
 * The initialQuery is only used for the Session owner. The accessType is used when a Session is created.
 * @param {*} param0 
 * @returns 
 */
export default function AIChat({
  useSessionId,
  messageId,
  chatHeight = '70vh',
  ownerOnly,
  category,
  room,
  roomId,
  roomName,
  accessType = ACCESS_TYPES.PROTECTED,
  ownerPaysThoughts,
  initialQuery = '',
}) {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()
  const { id: userId } = account ? account : {
    userId: ''
  }
  const [query, setQuery] = useState(initialQuery)
  const [sentInitialQuery, setSentInitialQuery] = useState(false)
  const [imageRequest, setImageRequest] = useState(false)
  const [chatCategory, setChatCategory] = useState(category)
  const { showAiWelcome } = useSelector(state => state.help)
  const { chatMessages, currentCollaborator, chatSessionId } = useSelector(
    (state) => state.chat
  )
  const [sessionRoomId, setSessionRoomId] = useState(roomId)
  const [showSessions, setShowSessions] = useState(false)
  const [shareToEmail, setShareToEmail] = useState('')
  const [showShareToEmail, setShowShareToEmail] = useState(false)
  const [error, setError] = useState()
  const [answerCopied, setAnswerCopied] = useState(false)
  const [global, setGlobal] = useState(false)
  const [dateSearch, setDateSearch] = useState(false)
  const [speaker, setSpeaker] = useState(null)
  const [connected, setConnected] = useState(false)
  const [explain, setExplain] = useState()
  const [helpTarget, setHelpTarget] = useState()
  const [showRelatedMessages, setShowRelatedMessages] = useState(false)
  const [showRelatedEmbeds, setShowRelatedEmbeds] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef()

  const [thinking, _setThinking] = useState(false)
  const thinkingRef = useRef(thinking)
  const setThinking = (data) => {
    thinkingRef.current = data
    _setThinking(data)
  }

  const [waiting, _setWaiting] = useState(false)
  const waitingRef = useRef(waiting)
  const setWaiting = (data) => {
    waitingRef.current = data
    _setWaiting(data)
  }

  const [currentSession, _setCurrentSession] = useState({
    accessType,
    userId,
  })
  const currentSessionRef = useRef(currentSession)
  const setCurrentSession = (data) => {
    currentSessionRef.current = data
    _setCurrentSession(data)
  }

  const [sessionUsers, _setSessionUsers] = useState([])
  const sessionUsersRef = useRef(sessionUsers)
  const setSessionUsers = (data) => {
    sessionUsersRef.current = data
    _setSessionUsers(data)
  }

  const [readAnswer, _setReadAnswer] = useState(false)
  const readAnswerRef = useRef(readAnswer)
  const setReadAnswer = (data) => {
    readAnswerRef.current = data
    _setReadAnswer(data)
  }

  const displayHelp = () => {
    return (
      <div onClick={() => setExplain(null)}>
        <p>Ask questions about all the music in Harmonize. Your chats are saved
          in AI <i>sessions</i>.
        </p>
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <FileCopyTwoTone style={{ fontSize: '2em' }} />&nbsp;Click this icon to copy your latest answer to the clipboard
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AddBox style={{ fontSize: '2em' }} />&nbsp;<div>Click this to create a new AI session</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SpeakerNotes style={{ fontSize: '2em' }} />&nbsp;<div>Click this to list all of your stored AI sessions</div>
        </div>
      </div>
    )
  }

  const explainAI = {
    contentFunction: displayHelp
  }
  /**
   * There must be a sessionId before this can be called.
   * 
   * Then, if there is a messageId, connect using that;
   * otherwise if there is a category, use that;
   * otherwise there must be a socketRoomId
   * 
   * @param {*} socketRoomId 
   */
  const configureAndConnectSocket = (socketRoomId, sid = currentSessionRef.current._id) => {
    if (!sid) {
      setError('Unable to connect: there is no Session')
    } else {
      let channelAddress = process.env.REACT_APP_AI_CHAT_SERVICE

      channelAddress += `${sid}/`
      if (messageId) {
        channelAddress += `messageId=${messageId}`
      } else if (category) {
        channelAddress += `category=${chatCategory}`
      } else if (socketRoomId) {
        channelAddress += `room=${socketRoomId}`
      } else {
        throw new Error('Missing category or roomId')
      }

      console.log(`Connect to ${channelAddress}`)
      socket = io(channelAddress)

      /*
       * A join is sent by all users. Only if the username matches this User should a query be
       * sent because it means that User is receiving its own join message.
       */
      socket.on('joinedAiChat', async (joinResponse) => {
        joinedAiChat(joinResponse)
      })

      /* When the Session owner leaves disconnect and that's it */
      socket.on('leftAiChat', async (leftResponse) => {
        logWithTime(`leftAiChat`, leftResponse)
        disconnectChannel()
      })

      /* Only collaborators who did not create the query should see this. */
      socket.on('querySent', async (queryRequest) => {
        logWithTime(`received querySent`, queryRequest)
        setWaiting(true)
        const { query } = queryRequest
        dispatch(addChatMessage({ query }))
      })

      socket.on('answer', async (response) => {
        setThinking(false)
        const {
          answer,
          cost,
          relatedEmbeds,
          relatedMetadata,
          sid,
          thoughtId
        } = response
        logWithTime(`Received answer thoughtId ${thoughtId}`, response)
        const responseMessage = {
          answer,
          cost,
          relatedEmbeds,
          relatedMetadata,
          thoughtId
        }
        if (relatedMetadata) {
          try {
            const relatedMessages = await getRelatedMessages(relatedMetadata)
            if (relatedMessages.length) {
              responseMessage.relatedMessages = relatedMessages
            }
          } catch (error) {
            console.error(`FAILED getting related post, ignored`, error)
          }
        }
        dispatch(addChatMessage(responseMessage))
        if (sid && !chatSessionId) {
          console.log(`Set sessionId to ${sid}`)
          dispatch(setChatSessionId(sid))
          try {
            const session = await getSession(sid, accessToken)
            setCurrentSession(session)
          } catch (error) {
            displayError(error, setError)
          }
        }
        setWaiting(false)
        //readAnswerText(answer)
      })
      socket.on('reload', (response) => {
        const { sessionId } = response
        populateChatFromSession(sessionId)
      })
      socket.on('error', async (response) => {
        const { message } = response
        console.log(`Received ERROR ${message}`)
        setError(message)
        //await updateAvailableThoughts()
        setWaiting(false)
        //Put back the query that was not executed
        const lastHistory = chatMessages[chatMessages.length - 1]
        if (lastHistory) {
          const { query } = lastHistory
          setQuery(query)
        }
        if (chatMessages.length <= 1) {
          dispatch(setChatMessages([]))
        } else {
          chatMessages.splice(chatMessages.length - 1, 1)
          dispatch(setChatMessages([...chatMessages]))
        }
      })
      socket.on('unauthorized', () => {
        dispatch(setAccessToken(null))
        history.push(`/SignIn/${roomName}`)
      })

      socket.on('connect', () => {
        logWithTime('socket connected')
        safeEmit('authorize', getStorageAccessToken())
      })
      socket.on('connect_error', () => {
        console.error(`Unable to connect to ${channelAddress}`)
        setError(`Unable to connect to Harmonize AI`)
        if (socket) {
          socket.disconnect()
          socket = null
        }
        setConnected(false)
      })

      /*
      socket.on('collaboratorControl', (collaborator) => {
        logWithTime(`collaboratorControl ${collaborator}`)
        dispatch(setCurrentCollaborator(collaborator))
      })
      */
    }
  }

  const channelDisconnected = () => {
    console.log('socket disconnected', socket)
    socket = null
    setConnected(false)
  }

  /**
   * When the AIChat connection to VortexService is authorized, a joinedAIChat event is emitted
   * that contains the connecting User and the current list of user _ids already connected
   * to the AIChat namespace.
   *
   * If the connected user is the owner, then send any initial query. Otherwise, if the list
   * of sessionUsers does not include the owner, we have to wait for that host to join.
   *
   * @param {*} joinResponse
   */
  const joinedAiChat = (joinResponse) => {
    logWithTime(`joinedAiChat`, joinResponse)
    channelConnected()
  }

  const channelConnected = () => {
    console.log('socket connected', socket)
    setConnected(true)
    //When the Social Service is killed with active connections this gets called back with a null socket
    if (socket) {
      socket.on('unauthorized', () => {
        channelDisconnected()
        setError('You are not signed in')
        history.replace('SignIn')
      })
      socket.on('disconnect', () => {
        channelDisconnected()
      })
    }
  }

  /**
   * This sends a leaveRoom to VortexService AIChat, which in turn disconnects it.
   */
  const disconnectChannel = () => {
    console.log(`AIChat disconnectChannel`)
    safeEmit('leaveRoom')
    setConnected(false)
    setError()
    setSessionUsers([])
    dispatch(setCurrentCollaborator('0'))
  }

  const displayAnswerCopied = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={answerCopied}
        autoHideDuration={3000}
        onClose={() => setAnswerCopied(false)}
      >
        <Alert onClose={() => setAnswerCopied(false)} severity='success'>
          <span>Answer copied to clipboard</span>
        </Alert>
      </Snackbar>
    )
  }
  const copyLatestAnswer = () => {
    if (chatMessages.length > 1) {
      navigator.clipboard.writeText(
        chatMessages[chatMessages.length - 1].answer
      )
      setAnswerCopied(true)
    }
  }

  const welcomeContent = () => {
    const fontSize = veryNarrow ? '0.9em' : 'inherit'
    return (
      <div style={{ padding: '0.5em' }} onClick={() => dispatch(setShowAiWelcome(false))}>

        <p>Ask questions about all the music in Harmonize. Your chats are saved
          in AI <i>sessions</i>.
        </p>
        <p style={{ display: 'flex', alignItems: 'center', fontSize }}>
          A good starting query is 'Tell me about this music'
        </p>

      </div>
    )
  }

  const displayAiWelcome = () => {
    console.log(`displayAiWelcome ${showAiWelcome}`)
    if (showAiWelcome) {
      return (<div onClick={() => dispatch(setShowAiWelcome(false))}>
        <BlurDialog content={welcomeContent} image={harmonySmall} hideHelp
          title='Welcome to Harmonize AI'
          closeIcon close={() => dispatch(setShowAiWelcome(false))}
          top={veryNarrow ? '10vh' : '15vh'} />
      </div>)
    }

  }

  const readAnswerText = (answer) => {
    if (readAnswerRef.current) {
      const u = new SpeechSynthesisUtterance(answer)
      speaker.speak(u)
    }
  }

  const getRelatedMessages = async (relatedMetadata) => {
    const messages = []
    if (relatedMetadata) {
      for (const metadata of relatedMetadata) {
        const { messageId } = metadata
        try {
          const message = await getMessage(messageId)
          //const messageWithLikes = await updateLikes(message)
          messages.push(message)
        } catch (error) {
          console.error(`error retrieving message ${messageId}`, error)
        }
      }
    }
    return messages
  }

  const relatedEmbedsLine = (relatedEmbeds) => {
    if (relatedEmbeds) {
      if (showRelatedEmbeds) {
        let allEmbeds = ''
        for (const emb of relatedEmbeds) {
          allEmbeds += emb.embed + '<br />'
        }
        const jsx = parse(allEmbeds)
        return (
          <div>
            <div style={aiChatStyles.label}>
              Hide embeds&nbsp;
              <LabelOff onClick={() => setShowRelatedEmbeds(false)} />
            </div>
            <div>{jsx}</div>
          </div>
        )
      } else {
        return (
          <div style={aiChatStyles.label}>
            Show embeds&nbsp;
            <Label onClick={() => setShowRelatedEmbeds(true)} />
          </div>
        )
      }
    } else {
      return null
    }
  }

  const relatedMessagesLine = (relatedMessages) => {
    if (relatedMessages) {
      return (
        <div>
          <span style={{ fontWeight: 'bold', justifyContent: 'center', display: 'flex' }}>Related Songs</span>
          {relatedMessages.map((msg, ix) => (
            <div key={ix} style={{ cursor: 'pointer' }} >
              <DisplayHarmonizePost content={msg} fromAi={true} selected={() => {
                disconnectChannel()
                history.push(`/song/${msg._id}`)
              }} />
            </div>
          ))}
        </div>
      )
    } else {
      return null
    }
  }

  /**
 * If the Thought was retrieved from the Core Service, then we have a thoughtId with the query.
 * Otherwise, the Thought was just created by the AI query and the id of the THought created for
 * that is returned with the answer and stored into the chatMessage answer.
 * @param {*} thoughtId 
 * @param {*} ix 
 * @returns 
 */
  const deleteChatLine = async (thoughtId, ix) => {
    let actualThoughtId = thoughtId
    if (!thoughtId && ix + 1 < chatMessages.length) {
      const answer = chatMessages[ix + 1]
      actualThoughtId = answer.thoughtId
    }
    if (actualThoughtId) {
      try {
        await deleteSessionThought(actualThoughtId, accessToken)
        populateChatFromSession(chatSessionId)
        safeEmit('reload', chatSessionId)
      } catch (error) {
        displayError(error, setError)
      }
    }
  }


  const displayDeleteChatLine = (thoughtId, ix) => {
    const { userId: sessionUserId } = currentSessionRef.current
    if (sessionUserId === userId) {

      return (
        <div title='Click to delete this Query and Answer' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <DeleteForeverTwoTone
            color='error'
            fontSize='small'
            onClick={(evt) => {
              evt.stopPropagation()
              deleteChatLine(thoughtId, ix)
            }}
          />
        </div>
      )
    }
  }

  const displayText = (chatEntry, ix) => {
    const { answer, relatedEmbeds, relatedMessages } =
      chatEntry
    const formattedAnswer = parse(replaceNewLine(answer))
    return (
      <div key={ix}>
        <p>A: {formattedAnswer}</p>
        {relatedEmbedsLine(relatedEmbeds)}
        {relatedMessagesLine(relatedMessages)}
        {/*urlReferences
          ? urlReferences.map((url, ix) => (
            <div key={ix}>
              <a
                href={`${url}`}
                target='_blank'
                referrerPolicy='no-referrer'
              >
                {url}
              </a>
            </div>
          ))
          : null*/}
      </div>)
  }

  const formatChatLine = (chatEntry, ix) => {
    const { thoughtId, query, answer } =
      chatEntry
    if (query) {
      return <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }} key={ix}>
        Q: {query}{displayDeleteChatLine(thoughtId, ix)}
      </div>
    } else {
      try {
        const parsedAnswer = JSON.parse(answer)
        if (Array.isArray(parsedAnswer)) {
          const img = parsedAnswer[0]
          return (
            <div key={ix} style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={img.url}
                style={{ width: '50%' }}
              />
            </div>
          )
        }
      } catch {

      }
      return displayText(chatEntry, ix)
    }

  }

  const displayChatLine = (chatEntry, ix) => {
    const { query } = chatEntry
    return (
      <div
        key={ix}
        style={query ? aiChatStyles.queryLine : aiChatStyles.answerLine}
      >
        {formatChatLine(chatEntry, ix)}
      </div>
    )
  }

  const displayChat = () => {
    if (account) {
      return (
        <div style={{ backgroundColor: 'white', width: '100%', display: 'flex', flexDirection: 'column', height: chatHeight }}>
          <div
            ref={ref}
            style={{
              border: '1px solid black',
              height: '100%',
              overflowY: 'scroll',
              paddingLeft: veryNarrow ? '0.25em' : '1em',
              width: '100%',
              paddingRight: veryNarrow ? '0.25em' : '1em',
            }}
          >
            {chatMessages.map((chat, ix) => displayChatLine(chat, ix))}
          </div>

          <InputAI
            roomName={roomName}
            getQuery={() => { return query }}
            updateQuery={(query) => setQuery(query)}
            send={() => {
              sendQuery(connected)
            }}

          />
        </div>
      )
    }
  }

  const displaySessions = () => {
    return (
      <div style={{ width: '100%' }}>
        <DisplaySessions
          sessionsHeight={veryNarrow ? '56vh' : '70vh'}
          handleSession={async (sessionId) => {
            console.log(`handleSession ${sessionId}`)
            setShowSessions(false)
            await populateChatFromSession(sessionId)
          }}
          sessionDeleted={async (sess) => {
            try {
              if (sess && sess.length) {
                setShowSessions(false)
                const { _id } = sess[0]
                const session = await getSession(_id, accessToken)
                dispatch(setChatSessionId(_id))
                setCurrentSession(session)
                await buildChatMessages(session.thoughts)
              } else {
                await createSession()
              }
            } catch (error) {
              displayError(error, setError)
            }
          }}
        />
      </div>
    )
  }

  const readyForCollaborators = (session) => {
    const { user } = session
    if (user !== userId) {
      const ix = sessionUsersRef.current.findIndex((c) => c === user)
      dispatch(setWaitingForHost(ix === -1))
      if (ix === -1) {
        console.log(
          `...readyForCollaborators WAITING for Session owner ${user}`
        )
      }
    }
  }

  const replaceNewLine = (contents) => {
    if (contents) {
      return contents.replace(/\n/g, '<br />')
    } else {
      return contents
    }
  }

  const scrollToBottom = (targetRef) => {
    if (targetRef.current) {
      const scroll =
        targetRef.current.scrollHeight - targetRef.current.clientHeight
      targetRef.current.scrollTo(0, scroll)
    }
  }

  const onSpeechCommand = (turnOn) => {
    setReadAnswer(turnOn)
    setQuery('')
    speaker.speak(new SpeechSynthesisUtterance(turnOn ? 'Started' : 'Stopped'))
  }
  const checkSpeechCommand = (queryToSend) => {
    const command = queryToSend.trim().toLowerCase()
    switch (command) {
      default:
        return false
      case 'start reading':
        onSpeechCommand(true)
        return true
      case 'stop reading':
        onSpeechCommand(false)
        return true
    }
  }

  const displayThinking = () => {
    if (thinkingRef.current) {
      return (

        <div
          style={{
            backgroundColor: 'rgba(127,127,127,0.6)',
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 150,
            backdropFilter: 'blur(5px)',
          }}
          onClick={() => setThinking(false)}
        >
          <div style={{ color: 'white', marginTop: '40vh' }}>

            <TimerProgress label='Thinking...' />
          </div>
        </div>
      )
    }
  }

  /**
   * 
   * @param {*} socketIsConnected
   */
  const sendQuery = (socketIsConnected) => {
    const queryLength = query.trim().length
    if (queryLength) {
      if (!checkSpeechCommand(query)) {
        try {
          if (!socketIsConnected) {
            configureAndConnectSocket(sessionRoomId)
          } else {
            //let updatedHistory = chatMessages
            //updatedHistory.push({ query })
            dispatch(addChatMessage({ query }))
            setQuery('')
            setError('')
            setWaiting(true)
            if (speaker && readAnswerRef.current) {
              speaker.speak(new SpeechSynthesisUtterance('Thinking'))
            }
            //dispatch(setChatMessages(updatedHistory))
            let queryParams = { query, global, brainSize: 1, imageRequest }
            if (dateSearch) {
              const createdAt = moment().subtract(60, 'days').unix()
              console.log(`dateSearch from ${createdAt}`)
              queryParams.createdAt = createdAt
            }
            console.log('sendQuery', queryParams)
            setThinking(true)
            safeEmit('query', queryParams)
          }
        } catch (error) {
          console.error(error)
          displayError(error, setError)
        }
      }
    }
  }

  const displayNoCredits = () => {
    if (ownerPaysThoughts) {
      return (
        <div>
          <p style={{ textAlign: 'center' }}>
            This room AI is paid by the room owner, but currently the credit
            balance is 0.
          </p>
          <ErrorLine error={error} />
        </div>
      )
    }
    return (
      <div>
        <h3 style={{ textAlign: 'center' }}>
          You are out of credits. <Link to='BuyCredits'>Click here</Link> to
          buy more
        </h3>
        <ErrorLine error={error} />
      </div>
    )
  }
  const displayLoading = () => {
    return (
      <div>
        <p style={{ textAlign: 'center' }}>Loading AI...</p>
        <ErrorLine error={error} />
      </div>
    )
  }

  /**
   * If initialQuery is specified then it has been set into query when the instance is created.
   * Once we have a Session we must send the query because this is when
   * configureAndConnectSocket is called.
  */
  const sendInitialQuery = () => {
    if (initialQuery && initialQuery.length && !sentInitialQuery) {
      console.log(`send initial query ${initialQuery}`)
      setSentInitialQuery(true)
      sendQuery(false)
    }
  }

  /**
   * If we have not started from an existing Session (useSessionId specified in AIChat call)
   * then we have to create a new one on the Core Service and use it to connect to the Social 
   * Service. Once we have it we can send a query.
   */
  const createSession = async () => {
    logWithTime(`createSession accessType ${accessType}`)
    try {
      resetCurrentSession(false)
      const aiIdentifier = roomId ? roomId : category
      const aiType = roomId ? AI_TYPE.STUDIO : AI_TYPE.CATEGORY
      const sess = await addSession(aiIdentifier, aiType, accessType, accessToken)
      if (sess) {
        const { _id, userId } = sess
        dispatch(setChatRoomId(roomId))
        dispatch(setChatSessionId(_id))
        logWithTime(`...new Session ${_id}`)
        setCurrentSession({ ...sess, userId })
        dispatch(setWaitingForHost(false))
        logWithTime('got new session', sess)
        setShowSessions(false)
        sendInitialQuery()
      } else {
        setError('Unable to create a Harmonize AI Session')
      }
    } catch (error) {
      displayError(error, setError)
    }
  }

  /**
   * 
   * @param {*} thought 
   * @param {*} msgOut 
   */
  const buildChatMessage = async (thought, msgOut) => {
    const { _id, query, answer, relatedMetadata } = thought
    const relatedMessages = await getRelatedMessages(relatedMetadata)
    msgOut.push({ query, thoughtId: _id })
    msgOut.push({ answer, relatedMessages })
  }

  /**
   * 
   * @param {*} thoughts 
   */
  const buildChatMessages = async (thoughts) => {
    let messages = []
    for await (const thought of thoughts) {
      await buildChatMessage(thought, messages)
    }
    dispatch(setChatMessages(messages))
  }

  /**
   * Retrieve the selected Session and its Thoughts, then populate chatMessages
   * with the Thoughts and if the sessionId has changed send the selected Session _id to the server.
   * 
   * Ensure the Session belongs to this Room.
   * 
   * @param {*} sid Id of Session to get
   */
  const populateChatFromSession = async (sid, loadSessions) => {
    logWithTime(`populateChatFromSession ${sid} loadSessions ${loadSessions} ownerOnly ${ownerOnly} connected ${connected}`)
    try {
      setWaiting(true)
      const session = await getSession(sid, accessToken)
      await buildChatMessages(session.thoughts)
      logWithTime(`populateChatFromSession got session`, session)
      if (!session) {
        await createSession()
      } else {
        const { thoughts, room, userId: sessionUserId } = session
        if (ownerOnly && sessionUserId !== userId) {
          logWithTime(`...populateChatFromSession: current user not Session owner`)
          await createSession()
        } else {
          setCurrentSession(session)
          dispatch(setChatRoomId(roomId))
          dispatch(setChatSessionId(sid))
          if (loadSessions) {
            setSessionRoomId(room)
          }
          //readyForCollaborators(session)
          if (isAccessAllowed(session)) {
            await buildChatMessages(thoughts)
            setShowSessions(false)
            if (!connected) {
              configureAndConnectSocket(room, sid)
            } else {
              if (sid !== chatSessionId) {
                dispatch(setChatSessionId(sid))
                if (connected) {
                  safeEmit('sessionId', { sessionId: sid })
                }
              }
            }

          }
        }
      }
    } catch (error) {
      console.error('...error', error)
      if (error.statusCode === 404) {
        await createSession()
      } else {
        resetCurrentSession(false)
        displayError(error, setError)
      }
    }
    setWaiting(false)
  }

  /**
   * Call this if the current session is deleted or refreshed.
   * @param {*} sendSessionId If true the sessionId is sent to the Social Service
   */
  const resetCurrentSession = (sendSessionId = true) => {
    dispatch(setChatRoomId(null))
    dispatch(setChatSessionId(null))
    dispatch(setChatMessages([]))
    dispatch(setWaitingForHost(false))
    setSessionUsers([])
    if (sendSessionId) {
      safeEmit('sessionId', { sessionId: null })
    }
  }

  const safeEmit = (msg, data) => {
    if (socket) {
      socket.emit(msg, data)
    }
  }

  const isAccessAllowed = (sess) => {
    const { accessType, userId: sessionUserId } = sess
    return accessType !== ACCESS_TYPES.PRIVATE || sessionUserId === userId
  }

  const displayNarrow = () => {

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

        <div
          style={
            aiChatStyles.chatDiv
          }
        >
          {/*displayInputArea()*/}
          {showSessions ? displaySessions() : displayChat()}
        </div>
      </div>
    )
  }

  const displayChatOrSessions = () => {
    if (veryNarrow) {
      return displayNarrow()
    } else {
      return (
        <div style={{ display: 'flex', flex: 1 }}>

          <div
            style={
              aiChatStyles.chatDiv

            }
          >
            {showSessions ? displaySessions() : displayChat()}
          </div>
        </div>
      )
    }
  }

  const displaySimple = () => {
    return (
      <>
        <ErrorLine error={error} />
        <div style={{ display: 'flex', width: '100%' }}>
          {displayChatOrSessions()}
        </div>
      </>
    )
  }
  const displayMain = () => {
    const { userId: sessionUserId, _id } = currentSession
    if (!isAccessAllowed(currentSession)) {
      return <div>This Session is private</div>
    } else {
      return (
        <div>
          {!messageId ?
            (<div>

              <div style={{ display: 'flex', position: 'relative', paddingTop: '1em', alignItems: 'center', justifyContent: 'center' }}>

                <div style={{ position: 'absolute', right: 10 }} title='Close AI'>
                  <Cancel style={{ fontSize: '2em' }} onClick={async () => {
                    console.log('delete currentSession', currentSession)
                    disconnectChannel()
                    dispatch(setShowAi(false))
                    if (!chatMessages.length) {
                      await deleteSession(_id, accessToken)
                      dispatch(setChatSessionId(null))
                    }
                  }} />
                </div>
              </div>

              <ErrorLine error={error} />
              {displayAnswerCopied()}
              {/*displayShareToEmail()*/}
              <div style={{ width: '100%' }}>
                <div style={aiChatStyles.chatDiv}>
                  {!(showSessions) ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div title='Help' style={aiChatStyles.button} >
                        <HelpOutline
                          color='primary'
                          onClick={(event) => {
                            setHelpTarget(event.currentTarget)
                            setExplain(explainAI)
                          }} />
                      </div>
                      <div
                        title={
                          showSessions
                            ? 'Click to resume AI chat'
                            : 'Click to show your AI sessions'
                        }
                        style={aiChatStyles.button}
                        onClick={() => {
                          setShowSessions(!showSessions)
                        }}
                      >
                        {showSessions ? (
                          <img
                            src={roundBrain}
                            style={{
                              height: '1.5em',
                            }}
                          />
                        ) : (
                          <SpeakerNotes color='primary' />
                        )}
                      </div>
                      <div
                        title={sessionUsersRef.current.length > 1 ? 'Click to create a new session. This will disconnect collaborators' : 'Click to create a new session'}
                        style={aiChatStyles.button}
                      >
                        <AddBox
                          color='primary'
                          onClick={async () => {
                            disconnectChannel()
                            await createSession()
                          }}
                        />
                      </div>

                      {/*displaySummarizeDate()*/}

                      <div
                        title='Click to copy the latest answer'
                        style={aiChatStyles.button}
                      >
                        <FileCopyTwoTone color='primary' onClick={() => copyLatestAnswer()} />
                      </div>

                    </div>
                  ) : null}
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  {displayChatOrSessions()}
                </div>
              </div>
            </div >) : displaySimple()
          }

          <div>
            {/*
            <InputAI
              roomName={roomName}
              getQuery={() => { return query }}
              updateQuery={(query) => setQuery(query)}
              send={() => {
                sendQuery(connected)
              }}
              sendCollaborator={(collaborator) => {
                safeEmit('collaboratorControl', collaborator)
                dispatch(setCurrentCollaborator(collaborator))
              }}
            />
            */}
          </div>
          {displayAiWelcome()}
          {displayThinking()}
          {
            explain && (
              <Explain
                source={explain}
                target={helpTarget}
                close={() => setExplain(null)}
              />
            )
          }
        </div >
      )
    }
  }

  const displaySummarizeDate = () => {
    if (process.env.REACT_APP_MODE === 'development') {
      if (dateSearch) {
        return (
          <div
            title='Click to disable summary'
            style={aiChatStyles.button}
            onClick={() => setDateSearch(false)
            }
          >
            <TimerOffTwoTone />
          </div >
        )
      } else {
        return (
          <div
            title='Click to summarize'
            style={aiChatStyles.button}
            onClick={async () => {
              await createSession()
              setDateSearch(true)
            }}
          >
            <DateRangeTwoTone />
          </div>
        )
      }
    }
  }

  /**
   * Effin a. OK the way these work is that each useEffect with its dependency is equivalent to the
   * old setState(),()=>func(). So by not having the waiting dependency on the second useEffect,
   * we ensure that the disconnect is only called when chatCategory changes or the page is exited.
   */
  useEffect(() => {
    //console.log(`AIChat useEffect waitingRef.current ${waitingRef.current} connected ${connected}`)
    scrollToBottom(ref)
  }, [waitingRef.current])


  /*
  useEffect(() => {
  
    return () => {
      console.log(`useEffect 2 return ${chatCategory} waiting ${waiting}`)
      disconnectChannel()
    }
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCategory])
  */

  useEffect(() => {
    console.log(`AIChat useEffect useSessionId ${useSessionId}`)
    if (useSessionId) {
      populateChatFromSession(useSessionId, true)
    } else {
      createSession()
    }
    /*
    const synth = window.speechSynthesis
    setSpeaker(synth)
  
    return () => {
      synth.cancel()
      setSpeaker(null)
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return displayMain()
}


/*
const shareSession = async () => {

    try {
      await sendShareEmailToUser(`<p>Hi! Please join me, ${firstName} ${lastName} in a collaborative Vortext AI session</p>
        <a href=http://localhost:3000/chat/${sessionId}> Click here for the session</a>
        </div >`, shareToEmail, accessToken)
    } catch (error) {
      displayError(error, setError)
    }
  }
}



const displayShareToEmail = () => {
  if (showShareToEmail) {
    return (
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <TextField
          value={shareToEmail}
          onChange={(event) => setShareToEmail(event.target.value)} //checkSendNow(event.target.value, connected)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              shareSession()
            }
          }}
          style={
            veryNarrow
              ? aiChatStyles.sendButtonNarrow
              : aiChatStyles.sendButton
          }
          autoFocus={!veryNarrow}
          fullWidth
          label={`Enter the email address to share this Session`}
          inputProps={{
            maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH,
          }}
        />
        <Send onClick={() => shareSession()} />
      </div>
    )
  } else {
    return false
  }
}
 

const displayShare = () => {
  const { user, accessType } = currentSession
  if (!showSessions && user === userId && accessType === ACCESS_TYPES.PROTECTED) {
    return <div title='Click to share this Session' style={aiChatStyles.button}
      onClick={() => { setShowShareToEmail(!showShareToEmail) }}>
      <ShareTwoTone />
    </div>
  } else {
    return null
  }
}
*/