import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from 'components/Card/Card.js'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import 'firebase/compat/auth'
import { isNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { CancelRounded, HelpOutlineTwoTone } from '@material-ui/icons'

const useStyles = makeStyles(styles)

const blurStyles = {
  card: {
    zIndex: 200,
    margin: 'auto',
    position: 'fixed',
    border: '0.25em outset rgb(221 177 89)',
  }
}

/** This is a full screen component that blurs its background. The top parameter refers to the position of the contained Card. 
 *  If bottom is provided the Card is position using that and top is ignored.
*/
export default function BlurDialog({ image, content, title, titleFunc, hideTitle, hideHelp, top = '25vh', bottom, animationTime = 700,
  closeIcon, close,
  actions }) {
  const narrow = isNarrow(useMediaQuery)

  const width = narrow ? '90vw' : '60vw'
  const left = narrow ? '5vw' : '20vw'

  let cardStyle = { ...blurStyles.card, width, left }
  if (bottom) {
    cardStyle.bottom = bottom
  } else {
    cardStyle.top = top
  }
  const [cardAnimaton, setCardAnimation] = useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, animationTime)
  const classes = useStyles()

  const displayHelp = () => {
    if (!hideHelp) {
      return (
        <p style={{ marginTop: '1em' }}>
          <span
            style={{
              marginLeft: '1.5em',
              height: '100%',
              verticalAlign: 'middle',
            }}
          >
            <HelpOutlineTwoTone color='primary' />
          </span>{' '}
          Whenever you see this symbol, click it for helpful information
        </p>

      )
    }
  }

  const displayTitle = () => {
    if (!hideTitle) {
      if (titleFunc) {
        return <p style={{ textAlign: 'center', margin: 0, width: '100%' }}>{titleFunc()}</p>
      } else {
        return (
          <h3 style={{ textAlign: 'center', margin: '0' }}>
            {title ? title : `Welcome to ${process.env.REACT_APP_NAME}!`}
          </h3>
        )
      }
    }
  }
  return (
    <>
      <div
        style={{
          backgroundColor: 'rgba(127,127,127,0.6)',
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 150,
          backdropFilter: 'blur(5px)',
        }}
      />
      <Card
        className={classes[cardAnimaton]}
        style={cardStyle}
      >
        <div>
          {image ? <img
            style={{ width: '100%', padding: '0.5em' }}
            src={image}
          /> : null}
          {displayTitle()}
          <div>
            {content()}
            {displayHelp()}

            <hr />
            {actions ? actions() :
              closeIcon ? <CancelRounded
                onClick={() => close ? close() : {}}
                style={{ fontSize: '2em', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }} /> :
                <p style={{ textAlign: 'right', fontStyle: 'italic' }}>
                  To close this window, just click it.
                </p>
            }
          </div>
        </div>
      </Card>
    </>
  )
}
