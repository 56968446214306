import { useDispatch, useSelector } from 'react-redux'
import { isVeryNarrow } from 'util/screenUtils'
import { Grid, useMediaQuery } from '@material-ui/core'

import { encryptAudioUrl } from 'util/encryptUtils'
import SongCard from './SongCard'
import { setPlayListIndex } from 'redux/actions/harmonizeActions'
import { useEffect } from 'react'
import { setAutoPlay } from 'redux/actions/harmonizeActions'

export default function StudioGrid() {
    const veryNarrow = isVeryNarrow(useMediaQuery)


    const { playList } = useSelector(state => state.harmonize)

    const dispatch = useDispatch()

    const displaySongs = () => {
        if (playList && playList.length) {
            return (
                <Grid container spacing={veryNarrow ? 2 : 3} style={{ width: '100%', paddingTop: '1em', margin: 0 }}  >
                    {playList.map((song, ix) => <Grid key={ix} item xs style={{ display: 'flex', justifyContent: 'center' }}
                        onClick={(evt) => {
                            evt.stopPropagation()
                            dispatch(setPlayListIndex(ix))
                        }}
                    >
                        <SongCard song={song} fromPlaylist
                            selected={() => {
                                dispatch(setPlayListIndex(ix))
                                dispatch(setAutoPlay(true))
                            }}
                        /></Grid>)}
                </Grid>
            )
        }
    }

    useEffect(() => {
        console.log(`StudioGrid useEffect`, playList)
    }, [playList])
    return displaySongs()
}