import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import embed from 'images/embed.png'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'


import styles from 'assets/jss/material-kit-react/views/profilePage.js'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Card from 'components/Card/Card'
import VortexHeader from 'components/Header/vortex/VortexHeader'

const useStyles = makeStyles(styles)

export default function AboutEmbeds() {
  const narrow = useMediaQuery('(max-width:400px)')


  const classes = useStyles()


  return (
    <div >
      <div style={{
        height: narrow ? '200vh' : '100vw'
      }}>
        <VortexHeader />
        <div className={classes.container}>
          <GridContainer style={{ justifyContent: 'center' }}>
            <GridItem xs={12} sm={12} md={8}>
              <Card >


                <div
                  className={classes.container}
                  style={{ paddingLeft: '1vw', paddingRight: '1vw' }}
                >
                  <h3>What Are Embeds and Why They Are Great!</h3>
                  Embeds are a way to include content from another site in Harmonize. They are much more powerful than clickable links.
                  Clickable links take you out to another site. Embeds include content directly in your Harmonize post, keeping
                  your reader's eyes on you.
                  <p>
                    For example, if you have a song in Spotify, and you share just the URL, then the only thing that appears
                    in your post is that URL. Your reader has to click that link, and that takes them out to Spotify and away from
                    reading what you wrote.
                  </p>
                  <p>If instead for that same song you share the "embed code", then when your reader sees your post, a small Spotify player
                    appears directly in that page. What's more, your reader can then play a preview of your song <i>even if the reader
                      does not have a Spotify account</i>.

                  </p>
                  <h4>Supported Embeds</h4>
                  Harmonize currently supports the following embeds:
                  <ul>
                    <li>Spotify</li>
                    <li>Apple Music</li>
                    <li>Amazon Music</li>
                    <li>Deezer</li>
                    <li>TikTok</li>
                    <li>YouTube videos</li>
                    <li>Google Docs</li>
                    <li>Google Presentations</li>
                    <li>Google Maps</li>
                  </ul>
                  You can include up to 5 embeds in any Harmonize post.
                  This is useful, for example, for musicians who have their music available in multiple streaming apps.
                  {process.env.REACT_APP_PRE_RELEASE !== 'true' ? <p>
                    When Harmonize AI includes post content in a Thought, if there are related embeds then
                    one of them will appear in the Thought answer.
                  </p> : null}
                  <h4>How to Embed</h4>
                  <b>First</b>, get the embed code for your content. The way you find the embed is to use the share option
                  and then tap this symbol:
                  <p>
                    <img src={embed} />
                  </p>
                  <p>
                    A word of advice:  Not all apps support embeds. Usually you can only get the embed code in the desktop version of a site.
                    For example,
                    if you use "Share" from the Spotify app, you will not see an embed option.
                    If you choose share for the same song from the Spotify
                    website, then you will see the embed option.
                  </p><p>
                    <b>Next</b>, copy that code and paste it into your Harmonize post. To include it, click this symbol:
                  </p>
                  <p>
                    <img src={embed} />
                  </p>
                  <p>
                    Then paste the code.
                    The embed
                    will be sent with the rest of your message.
                  </p>
                  <p>If you attempt to paste embed code Harmonize does not support, you will receive an error.</p>
                  <h4>Multiple embeds</h4>
                  Once you have a single embed in your post, you can add more by tapping the + symbol that is displayed in the
                  line of embed symbols.

                </div>

              </Card>

            </GridItem>
          </GridContainer>
        </div>


        <Footer position={'fixed'} />
      </div>

    </div>
  )
}
