import React, { useState } from 'react'
import { io } from 'socket.io-client'
import { AddCircle, Face, HelpOutline } from '@material-ui/icons'
import { primaryColor } from 'assets/jss/material-kit-react.js'
import { useDispatch, useSelector } from 'react-redux'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isVeryNarrow } from 'util/screenUtils'
import {
  useMediaQuery,
} from '@material-ui/core'
import DisplayHarmonizePost from './DisplayHarmonizePost'
import { canWriteRoom } from 'util/transactionUtils'
import { displayError } from 'util/screenUtils.js'
import { getAndConnectRoom } from 'controllers/VortexController.js'
import { getPublicUserById } from 'controllers/AccountController.js'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useRef } from 'react'
import TimerProgress from 'components/mint/TimerProgress.js'
import { logWithTime } from 'util/screenUtils.js'
import { isAdminUser } from 'util/adminUtils.js'
import BlurDialog from 'components/utility/BlurDialog.js'
import harmonySmall from '../images/harmony-small.jpg'

import { setShowWelcome } from 'redux/actions/helpActions.js'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom.js'

import { setWaitingForHost } from 'redux/actions/chatActions.js'
import { incrementRoomViews } from 'controllers/VortexController.js'
import { setPlayList } from 'redux/actions/harmonizeActions'
import { setPlayListIndex } from 'redux/actions/harmonizeActions'
import { PLAYER_MODES } from 'redux/reducers/harmonizeReducer'
import HarmonizePlayer from 'components/harmonize/HarmonizePlayer'
import { isGooglebot } from 'util/screenUtils'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { setMessages } from 'redux/actions/messagesActions'
import { setCurrentChannel } from 'redux/actions/messagesActions'
import { getMessage } from 'controllers/VortexController'
import { setPlayerMode } from 'redux/actions/harmonizeActions'
import { setCurrentCollection } from 'redux/actions/harmonizeActions'
import { setAutoPlay } from 'redux/actions/harmonizeActions'
import { setCollections } from 'redux/actions/harmonizeActions'
import { getCollections } from 'controllers/HarmonizeController'
import ErrorLine from 'components/ErrorLine'
import CreateHarmonizePost from './CreateHarmonizePost'
import Chat from 'components/harmonize/Chat'
import SignInComponent from 'components/SignInComponent'
import { setShowSignIn } from 'redux/actions/helpActions'
import { setSongMediaIcons } from 'redux/actions/songActions'
import moment from 'moment'
import { createRsaPublicKey } from 'util/encryptUtils'
import { encryptAudioUrl } from 'util/encryptUtils'
import { LIKED_COLLECTION } from 'util/postUtils'
import { setFullscreen } from 'redux/actions/harmonizeActions'
import { useCallback } from 'react'
import { setHideOverlay } from 'redux/actions/harmonizeActions'
import { STUDIO_COLLECTION } from 'util/postUtils'
import { COLLECTION_TYPE } from 'util/postUtils'
import StudioGrid from 'components/harmonize/StudioGrid'

let socket, initialized
export default function Studio({ defaultRoom, publicRoomName, roomHandle, startWithPost = false }) {
  //See https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const { requestedCollectionId, songId } = useParams()
  const [requestedMessageId, setRequestedMessageId] = useState(songId)
  const { showWelcome } = useSelector((state) => state.help)

  const accessToken = getStorageAccessToken()
  const { requestedRoom, requestedPublicRoom } = useParams()

  const storageAccount = getStorageAccount() //useSelector(state => state.account) Can't use this for persisted account
  const userId = storageAccount ? storageAccount.id : null
  const username = storageAccount ? storageAccount.username : null
  const { currentCollection, fullscreen, playerMode, playList, playListIndex, reactorPlay, showChat } = useSelector(state => state.harmonize)
  const { currentChannel, currentRoom, messages } = useSelector(state => state.messages)
  const [requestedMessages, setRequestedMessages] = useState()
  const [error, setError] = useState()
  const [createPost, setCreatePost] = useState(startWithPost)
  const [roomOwner, setRoomOwner] = useState()
  const [hasAccessToken, setHasAccessToken] = useState(accessToken !== null)
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [loadFailed, setLoadFailed] = useState(false)
  const [editingMessageId, setEditingMessageId] = useState(null)
  const [commentToAdd, setCommentToAdd] = useState()
  const [commentToDelete, setCommentToDelete] = useState()
  const [messageToUpdate, setMessageToUpdate] = useState()
  const [messageToDelete, setMessageToDelete] = useState()
  const history = useHistory()
  const dispatch = useDispatch()
  const ref = useRef()

  const getSocket = () => {
    return socket
  }

  const displayIntro = () => {
    const fontSize = veryNarrow ? '0.9em' : 'inherit'
    return (
      <div onClick={() => dispatch(setShowWelcome(false))}>
        <p style={{ textAlign: 'center', fontSize }}>A music site for discerning musicians and listeners</p>
        <hr />
        <p>To display all songs in this studio, click 'Playlists' and select 'Studio'</p>
        <p style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <HelpOutline style={{ fontSize: '2em' }} />&nbsp;Click this icon on the music player for a description of the player controls
        </p>
        <div style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <Face style={{ fontSize: '2em' }} />&nbsp;<div>To change your profile, select <b>My Profile</b> from the <b>Account</b> menu
            on the home page</div>
        </div>
      </div>
    )
  }
  const getPublicRoomName = (roomToCheck) => {
    if (roomToCheck) {
      const { accessType, name } = roomToCheck
      if (accessType === ACCESS_TYPES.PUBLIC) {
        return name
      }
    } else if (requestedPublicRoom) {
      return requestedPublicRoom
    }
    return publicRoomName
  }

  /* The following methods are used to track the visibility state of the browser. */
  const [visible, _setVisible] = useState('visible')
  const visibleRef = useRef(visible)
  const setVisible = (data) => {
    visibleRef.current = data
    //logWithTime(`setVisible to ${visibleRef.current}`)
    _setVisible(data)
  }


  /**
     * This will be called e.g. when another page in Harmonize is selected, so don't null the currentRoom.
     * Otherwise going back to this page will have a null currentRoom and do nothing.
  
     * @param {*} resetRoom If true (default) room and messages are reset.
     */
  const channelDisconnected = (resetRoom = true) => {

    setError()
    socket = null
  }

  const [scrollFetchOff, _setScrollFetchOff] = useState(false)

  const scrollFetchOffRef = useRef(scrollFetchOff)
  const setScrollFetchOff = (data) => {
    scrollFetchOffRef.current = data
    _setScrollFetchOff(data)
  }

  const [endOfMessages, _setEndOfMessages] = useState(false)
  const endOfMessagesRef = useRef(endOfMessages)
  const setEndOfMessages = (data) => {
    endOfMessagesRef.current = data
    _setEndOfMessages(data)
  }

  const [messagesOffset, _setMessagesOffset] = useState(0)

  const messagesOffsetRef = useRef(messagesOffset)
  const setMessagesOffset = (data) => {
    messagesOffsetRef.current = data
    _setMessagesOffset(data)
  }


  const deleteMessage = () => {
    if (messageToDelete) {
      console.log('deleteMessage', messageToDelete)
      const updatedMessages = messages.toSpliced(
        messages.findIndex((m) => m._id === messageToDelete.messageId),
        1
      )
      dispatch(setMessages(updatedMessages))
      createPlaylist(updatedMessages)
      setMessageToDelete(null)
    }
  }

  const updateLike = (messageId, liked) => {
    const likeMessage = messages.find((m) => m._id === messageId)
    if (likeMessage) {
      const updatedMessages = messages.toSpliced(
        messages.findIndex((m) => m._id === messageId),
        1,
        { ...likeMessage, liked }
      )
      dispatch(setMessages(updatedMessages))
    }
  }


  const updateMediaIcons = (media) => {
    console.log(`updateMediaIcons`, media)
    dispatch(setSongMediaIcons([]))
    let icons = []
    media.forEach((m, index) => {
      if (m) {
        const { mimeType, imageType, source, sourceName } = m
        icons.push({ index, name: sourceName, source, mimeType, imageType })
      }
    })
    dispatch(setSongMediaIcons(icons))
  }

  /**
   * When we receive a broadcast updated Message, it does not contain the liked flag.
   * Therefore preserve it from the current state of the Message.
   * 
   * An update may change the studio (room) for a Message. Therefore filter out updates
   * that don't belong in the current studio

   * @param {*} message 
   */
  const updateMessage = () => {
    if (messageToUpdate) {
      const { _id: messageId, media: updatedMedia, roomName } = messageToUpdate
      const { name: currentRoomName } = currentRoom
      const currentMessage = messages.find((m) => m._id === messageId)
      const liked = currentMessage ? currentMessage.liked : false
      const media = [...updatedMedia]
      const revisedMessage = { ...messageToUpdate, liked, media }
      updateMediaIcons(media)
      //console.log('==> revisedMessage', revisedMessage)
      const messageIx = messages.findIndex((m) => m._id === messageToUpdate._id)
      if (messageIx !== -1) {
        console.log(`messageToUpdate roomName ${roomName} currentRoomName ${currentRoomName}`)
        const updatedMessages = roomName.toLowerCase() === currentRoomName ? messages.toSpliced(messageIx, 1,
          revisedMessage
        ) : messages.toSpliced(messageIx, 1)

        console.log('==> updatedMessages', updatedMessages)
        dispatch(setMessages(updatedMessages))
        setMessagesOffset(0)
        createPlaylist(updatedMessages, true)
      }
      setMessageToUpdate(null)
    }
  }

  /**
   * Look for the parent Message in messages, and if found push the newComment into its
   * comments array. If the message is not found the comment is ignored.
   * @returns
   */
  const addCommentToMessage = () => {
    if (commentToAdd) {
      console.log('addCommentToMessage', commentToAdd)
      const { comment, messageId } = commentToAdd
      const message = messages.find((m) => m._id === messageId)
      if (message) {
        if (message.comments) {
          message.comments.push(commentToAdd)
        } else {
          message.comments = [commentToAdd]
        }
        console.log(`...Added comment ${comment} to messageId ${messageId}`)
        dispatch(setMessages([...messages]))
      }
    }
  }

  const deleteCommentFromMessage = () => {
    if (commentToDelete) {
      const { commentId, messageId } = commentToDelete
      const message = messages.find((m) => m._id === messageId)
      if (message && message.comments) {
        message.comments = message.comments.filter((c) => c._id !== commentId)
        console.log(`Deleted comment ${commentId} from messageId ${messageId}`)
        dispatch(setMessages([...messages]))
      }
    }
  }

  /**
   * We DO NOT have to use the redux state in here because the local state is not seen and I don't got
   * the time to figure out why. OK now we know. See:
   * https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
   * @param {*} channel
   */
  const channelConnected = (channel, existing, studioOwner) => {
    console.log(`channelConnected ${channel} existing ${existing}`)
    dispatch(setCurrentChannel(channel))
    setError('')

    //console.log(`socket connected channel ${channel} existing ${existing}`, socket)
    //When the Social Service is killed with active connections this gets called back with a null socket
    if (socket) {
      socket.on('unauthorized', () => {
        channelDisconnected(channel)
        setError('You are not signed in')
        history.replace('/SignIn')
      })
      socket.on('disconnect', () => {
        //console.log(`received socket disconnect IGNORED`)
        //channelDisconnected()
      })
      socket.on('addComment', (comment) => {
        console.log('addComment received comment', comment)
        setCommentToAdd(comment)
      })
      socket.on('deleteComment', (comment) => {
        console.log('deleteComment', comment)
        setCommentToDelete(comment)
      })
      socket.on('addMessage', async (message) => {
        console.log('addMessage received message', message)

        const { _id } = message
        const existing = messages.findIndex((m) => m._id === _id) !== -1
        if (existing) {
          console.error(`received existing message ${_id} IGNORED`)
        } else {
          //Disable autoPlay so you don't get a "need to touch first" audio error
          dispatch(setAutoPlay(false))
          const updatedMessages = [message, ...messages]
          dispatch(setMessages(updatedMessages))
          createPlaylist([...updatedMessages])
        }
      })
      socket.on('liked', async (messageId) => {
        console.log('liked', messageId)
        updateLike(messageId, true)
      })
      socket.on('unliked', async (messageId) => {
        console.log('unliked', messageId)
        updateLike(messageId, false)
      })
      socket.on('updateMessage', async (message) => {
        console.log('updateMessage received message', message)
        setMessageToUpdate(message)
      })
      socket.on('deleteMessage', (message) => {
        console.log('deleteMessage', message)
        setMessageToDelete(message)
      })
      socket.on('requestedMessages', async (messages) => {
        setRequestedMessages(messages)
        setScrollFetchOff(false)
      })
      socket.on('error', (message) => {
        displayError(message, setError)
      })
      //Only show currentCollection if it belongs to this room
      if (!existing) {
        if (currentCollection) {
          if (requestedMessageId) {
            dispatch(setCurrentCollection(null))
            getServiceMessages()
          } else {
            const { user } = currentCollection
            console.log(`\n ***>initial currentCollection request ${user} `, studioOwner)
            if (studioOwner.user === user) {
              getServiceMessages(currentCollection)
            } else {
              dispatch(setCurrentCollection(null))
              getServiceMessages()
            }
          }
        } else {
          getServiceMessages()
        }
      }
    }
  }

  /** Here is some bogosity: if the accessToken is provided as an element in the io constructor, it cannot
   *  be updated after the first connection. So e.g. if it starts null, and you sign in, even though everything
   * else in here sees the new accessToken, the io instance does not. SO we changed this up to send
   * a base64 encoded string on the connection request et voila.
   */
  const configureSocket = (channel, studioOwner) => {
    try {
      const channelAddress = `${process.env.REACT_APP_SOCIAL_CHAT_SERVICE}${channel}`
      logWithTime(`Connect to ${channelAddress}`)
      socket = io(channelAddress, {
        reconnectionDelay: 10000
      })
      socket.on('connect', () => {
        channelConnected(channel, false, studioOwner)
      })
      socket.on('connect_error', () => {
        displayError({ message: `Unable to connect to Harmonize Social Service` }, setError)
        if (socket) {
          socket.disconnect()
        }
      })
    } catch (error) {
      console.error(error)
      displayError(error, setError)
    }
  }

  const connectExisting = () => {
    logWithTime(`Connect to existing ${currentChannel}`)
    try {
      const channelAddress = `${process.env.REACT_APP_SOCIAL_CHAT_SERVICE}${currentChannel}`
      //console.log(`Reconnect to ${channelAddress}`)
      socket = io(channelAddress, {
        reconnectionDelay: 10000,
      })
      socket.on('connect', () => {
        channelConnected(currentChannel, true, roomOwner)
      })
      socket.on('connect_error', () => {
        displayError({ message: `Unable to connectExisting to Harmonize Social Service` }, setError)
        if (socket) {
          socket.disconnect()
        }
      })
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  /**
   * This disconnects the socket but leaves everything else in place.
   */
  const disconnectExisting = () => {

    logWithTime(
      socket ? 'disconnectExisting' : 'WARNING no socket for disconnectExisting'
    )
    leaveRoom()
    socket = null
  }

  const disconnectChannel = (resetRoom) => {
    console.log(`disconnectChannel resetRoom ${resetRoom}`, socket)
    leaveRoom()
    channelDisconnected(resetRoom)
  }

  /**
   * Request messages from the Social Service either:
   * 1) By the currentCollection. This works in any Studio.
   * 2) For this Studio. This only makes sense in an artist Studio, i.e., a Studio in which Messages have been posted
   */
  const getServiceMessages = (requestedCollection) => {
    console.log('\n ***>getServiceMessages', requestedCollection)

    let query = { userId, start: 0, count: parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE) }
    setMessagesOffset(0)
    setEndOfMessages(false)
    dispatch(setMessages([]))
    dispatch(setPlayList([]))
    dispatch(setPlayListIndex(0))
    dispatch(setAutoPlay(false))
    /* Disable scroll on fetch because if the messages retrieved are too short display-wise another fetch is triggered */
    setScrollFetchOff(true)
    if (requestedCollection) {
      initialized = false
      setRequestedMessageId(null)
      const { _id: collectionId, collectionType } = requestedCollection
      if (collectionType !== COLLECTION_TYPE.STUDIO) {
        query = { userId, collectionId, collectionType }
      }
    } else if (requestedMessageId) {
      query = { userId, requestedMessageId }
    }
    console.log('...getServiceMessages query', query)
    safeEmit('getMessages', query)
  }

  const leaveRoom = () => {
    if (socket) {
      safeEmit('leaveRoom', { userId, username })
    }
  }

  const leaveChat = () => {
    if (socket) {
      safeEmit('chatleave', { userId, username })
    }
  }

  const getRoomOwner = async (room) => {
    console.log('getRoomOwner', room)
    const { user } = room
    const publicUser = await getPublicUserById(user)
    if (publicUser.status === 200) {
      const owner = { ...publicUser, user }
      console.log('Room owner', owner)
      setRoomOwner(owner)
      await fetchRoomCollections(owner)
      return owner
    } else {
      return null
    }
  }

  /**
   * Builds the encrypted URL parameters for the audio or video and returns that and some other things
   * @param {*} md 
   * @param {*} messageId 
   * @param {*} newFormat   True for any audio created after some date
   * @param {*} rsaKeyPair  I only the pub key in production
   * @returns { mimeType, duration, source: url, sourceName, mediaSource }
   */
  const buildEntryMedia = async (md, messageId, newFormat, rsaKeyPair) => {
    let url
    const { pub } = rsaKeyPair
    const { source: mediaSource, mimeType, imageType, sourceName, duration } = md
    switch (mimeType) {
      case 'audio/mpeg':
        url = await encryptAudioUrl(mediaSource, sourceName, pub, !newFormat)
        break
      case 'video/mp4':
        //:apiKey/:username/:foldername/:subfoldername/:videoname
        //mediaSource is e.g. cjvilla-photo/home/PerfectLife and is defined when Message is stored on Core Service
        //Can't get the emcrypted call to work with React-Player. The m3u8 comes back but ReactPlayer never makes
        //a call for the TS files.
        const videoParameters = `${process.env.REACT_APP_API_KEY}/${mediaSource}/${sourceName}`
        //const videoEncryptedParams = await encryptString(videoParameters, pub)
        url = `${process.env.REACT_APP_CORE_SERVICE}media/video/${videoParameters}`
        break
      default:
        break
    }
    return { mimeType, imageType, duration, source: url, sourceName, mediaSource }

  }

  const buildAllEntryMedia = async (msg, entryMedia, rsaKeyPair) => {
    //console.log('buildAllEntryMedia')
    const { _id: messageId, name, media, createdAt, username } = msg
    const newFormat = moment(createdAt).isAfter('2024-07-03')
    return Promise.all(
      media.map(async (md) => {
        const msgMedia = await buildEntryMedia(md, messageId, newFormat, rsaKeyPair)
        const { mimeType, imageType, duration, source: url, sourceName, mediaSource } = msgMedia
        entryMedia.push({ messageId, name, createdAt, mimeType, imageType, duration, source: url, sourceName, username, mediaSource })
      })
    )
  }

  /**
   * The default values assigned to firstName etc are for the Messages created before we started
   * collecting this info when the Message is created.
   * @param {*} m 
   * @param {*} playlist 
   * @param {*} rsaKeyPair 
   */
  const buildPlaylistEntry = async (m, playlist, rsaKeyPair) => {
    const { _id: messageId, name, media, userId, numListens, firstName = 'CJ', lastName = 'Villa', handle = 'cj' } = m
    if (media && media.length) {
      let entryMedia = [], hasMp3
      await buildAllEntryMedia(m, entryMedia, rsaKeyPair)
      hasMp3 = entryMedia.findIndex(em => em.mimeType === 'audio/mpeg') !== -1
      if (hasMp3) {
        playlist.push({ messageId, userId, entryMedia, name, numListens, firstName, lastName, handle })
      }
    }
  }

  const buildNewPlaylistEntries = (newMessages, playlist, rsaKeyPair, initialize) => {
    //console.log(`buildNewPlaylistEntries ${messages.length}`)
    return Promise.all(
      newMessages.map(async (m, ix) => {
        //console.log(`buildPlaylistEntry ${ix}`)
        await buildPlaylistEntry(m, playlist, rsaKeyPair)

      })
    )
  }

  /**
   * A playList comprises one entry for every message that has an mp3.
   * The entry comprises an array with an entry for each mp3 or mp4 file.
   * 
   * NOTE: On 8/1/2024 MP3s started being created on the Core Service and not in Backblaze.
   * As a result there are two Core Service endpoints.

   * @param {*} newMessages The messages for the playList.
   * @param {*} initialize  If true this is the first set in the playlist
   */
  const createPlaylist = async (newMessages, initialize) => {
    console.log(`createPlaylist initialize ${initialize} ${newMessages.length} newMessages currentCollection:`, currentCollection)
    let newPlayList = []
    /*
    if (initialize) {
      if (newMessages.length < parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE)) {
        console.log(`\n*** end of messages, offset ${messagesOffsetRef.current} total length ${newMessages.length}`)
        setEndOfMessages(true)
      }
      setMessagesOffset(0)//messagesOffsetRef.current + newMessages.length)
    }
    */
    const rsaKeyPair = await createRsaPublicKey()
    await buildNewPlaylistEntries(newMessages, newPlayList, rsaKeyPair, initialize)
    const updatedPlayList = initialize ? newPlayList : [...playList, ...newPlayList]
    completeCreatePlaylist(updatedPlayList, newMessages, initialize)
  }

  /**
   * 
   * @param {*} updatedPlayList The playList as just updated in createPlaylist
   * @param {*} newMessages     The Messages that were processed in createPlaylist
   * @param {*} initialize      If true and requestedMessageId is not null then set the playListIndex to the
   *                            entry corresponding to the requested Message
   */
  const completeCreatePlaylist = (updatedPlayList, newMessages, initialize) => {
    console.log(`**************completeCreatePlaylist ${updatedPlayList.length} 
      messagesOffset ${messagesOffsetRef.current}`)
    if (initialize) {
      dispatch(setMessages(newMessages))
      setMessagesOffset(newMessages.length)
    } else {
      dispatch(setMessages([...messages, ...newMessages]))
      setMessagesOffset(messagesOffsetRef.current + newMessages.length)
    }
    if (initialize) {
      if (requestedMessageId) {
        dispatch(setPlayList(updatedPlayList))
        const ix = updatedPlayList.findIndex(pl => pl.messageId === requestedMessageId)
        if (ix !== -1) {
          dispatch(setPlayListIndex(ix))
          dispatch(setCurrentCollection(null))
          setRequestedMessageId(null)
        } else {
          dispatch(setPlayListIndex(0))
        }

        /*
        } else if (requestedCollectionId) { //Currenty UNUSED is needed for collection sharing
          console.log(`createPlaylist initialize requestedCollectionId ${requestedCollectionId}`)
          const currentColl = getRequestedCollection(roomOwner)
          collectionPlaylist(currentColl)
        */
      } else {
        dispatch(setPlayList(updatedPlayList))
        //dispatch(setPlayListIndex(0))
      }
    } else {
      dispatch(setPlayList(updatedPlayList))
      //dispatch(setPlayListIndex(0))
    }
  }

  /**
   * Create the playlist from the collection. If that is null, reset the currentCollection.
   * Then call requestMessages
   * @param {*} collection
   */
  const collectionPlaylist = async (collection) => {
    console.log('collectionPlaylist', collection)
    setError('')
    dispatch(setCurrentCollection(collection))
    if (collection) {
      dispatch(setPlayerMode(PLAYER_MODES.COMPACT))
    }
    getServiceMessages(collection)

  }

  /** To shuffle the playList you actually must shuffle the containing messages, then rebuild the playList */
  const shufflePlaylist = () => {
    const originalMessages = [...messages]
    originalMessages.sort(() => Math.random() - 0.5)
    console.log('shuffled messages', originalMessages)
    createPlaylist(originalMessages, true)
  }

  /**
   * Don't display the HarmonizePlayer until there is something in the playList.
   * @returns 
   */
  const displayHarmonizePlayer = () => {
    if (!loading && loaded) {
      //console.log('displayHarmonizePlayer')
      return (
        <HarmonizePlayer
          roomOwner={roomOwner} messageSocket={getSocket}
          setCollectionPlaylist={(col) => collectionPlaylist(col)}
          enterFullScreen={fullScreenHandle.enter}
          shuffle={() => shufflePlaylist()} />
      )
    }
  }

  const isAccessAllowed = () => {
    const { accessType, user } = currentRoom
    return isAdminUser() ||
      accessType !== ACCESS_TYPES.PRIVATE || user === userId
  }

  const updatePlayListSelection = (messageId) => {
    //console.log(`selected messageID ${messageId}`, playList)
    const selectedIndex = playList.findIndex(pl => pl.messageId === messageId)
    if (selectedIndex !== -1) {
      dispatch(setPlayListIndex(selectedIndex))
    }
  }

  const displayCreatePost = () => {
    if (accessToken) {
      return (
        <CreateHarmonizePost socket={getSocket} close={() => { setCreatePost(false); setEditingMessageId(null) }} messageId={editingMessageId}
        />
      )
    } else {
      history.replace('/')
    }
  }

  const displayPost = (msg) => {
    if (msg && !reactorPlay) {
      return (
        <DisplayHarmonizePost
          key={msg._id}
          content={msg}
          selected={() => {
            updatePlayListSelection(msg._id)
            dispatch(setAutoPlay(true))
          }}
          accessType={
            getPublicRoomName()
              ? ACCESS_TYPES.PUBLIC
              : ACCESS_TYPES.PROTECTED
          }
          room={currentRoom}
          messageSocket={socket}
          displayTitle={playerMode === PLAYER_MODES.POST}
          editPost={() => setEditingMessageId(msg._id)}

        />
      )
    }
  }


  const hasMedia = (m) => {
    const { media } = m
    if (media) {
      for (const md of media) {
        if (md && md.mimeType === 'audio/mpeg') {
          return true
        }
      }

    }
    return false
  }

  const
    displayNoSongs = () => {
      const noSongs = currentCollection ? ` for ${currentCollection.name}` : ' in your studio'
      return (
        <div style={{
          width: '100%', padding: '1em', color: 'white',
          justifyContent: 'center', border: '1px solid white',
          textAlign: 'center'
        }}>
          <h4>There are no songs {noSongs} yet</h4>
          <p>Once you like some songs, they will appear in your Liked playlist</p>
        </div>
      )
    }
  /**
   * If we are crawled by Googlebot then return all posts as in LIST mode.
   * Otherwise return one or more messages using displayPost.
   * 
   * @returns 
   */
  const displayPostForMode = () => {
    if (isGooglebot(window.navigator.userAgent)) {
      return messages.map((m => displayPost(m)))
    }
    if (requestedMessageId) {
      switch (playerMode) {
        case PLAYER_MODES.GRID:
          if (!reactorPlay) {
            return <StudioGrid />
          } else {
            return null
          }
        default:
          return displayPost(messages.find(m => m._id === requestedMessageId))
      }
    }

    if (endOfMessagesRef.current && !messages.length) {
      return displayNoSongs()
    }

    switch (playerMode) {

      case PLAYER_MODES.GRID:
        if (fullscreen) {
          return messages.map((m => displayPost(m)))
        } else if (!reactorPlay) {
          return <StudioGrid />
        } else {
          return null
        }
      case PLAYER_MODES.POST:
        return messages.map((m => displayPost(m)))
      case PLAYER_MODES.COMPACT:
        return messages.map((m => {
          if (hasMedia(m)) {
            return displayPost(m)
          } else {
            return null
          }
        }))
      default:
        const { accessType } = currentRoom
        return displayPost(messages.find(m => m._id === (!playList.length && accessType === ACCESS_TYPES.PRIVATE ? messages[playListIndex].messageId : playList[playListIndex].messageId)))
    }

  }

  /** Posts can only be added in the PRIVATE room that belongs to the artist. */
  const displayAddPost = () => {
    if (accessToken && currentRoom) {
      const { accessType } = currentRoom
      if (accessType === ACCESS_TYPES.PRIVATE) {
        return (
          <div style={{
            display: 'flex', position: 'fixed', bottom: '4vh',
            right: veryNarrow ? '0.25em' : '0.5em'
          }}>
            {!(reactorPlay || showChat) && canWriteRoom(currentRoom, userId) ? (
              <div
                title='Click to add a song'
                onClick={() => setCreatePost(!createPost)}
              >
                <AddCircle
                  color='primary'
                  style={{
                    zIndex: 200,
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    borderRadius: '4em',
                    fontSize: '4em',
                  }}
                />
              </div>) : null}
          </div>
        )
      }
    }
  }

  /** If in player mode, we only ever display the post for the current audio */
  const displayOnCondition = () => {
    //console.log(`displayOnCondition loading ${loading} loadFailed ${loadFailed} `)
    if (loadFailed) {
      return (
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Unable to load this room
        </p>
      )
    } else if (loading) {
      return <TimerProgress label='Loading...' />
    } else {

      if (!isAccessAllowed()) {
        return (
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            This room is private
          </p>
        )
      } else {
        return (
          <div>
            <div >
              {displayPostForMode()}
            </div>
            {accessToken && showChat ? <div style={{ position: 'fixed', bottom: '4vh', width: '100%' }}>
              <Chat socket={getSocket} setError={setError} roomOwner={roomOwner} /></div> : null}
            {displayAddPost()}
          </div>
        )
      }
    }
  }

  const signInContent = () => {
    const { fullName } = roomOwner
    return (
      <div style={{ fontSize: veryNarrow ? '.75em' : '1em', lineHeight: 'normal' }}>
        Create your free account or sign in to enjoy the music of {fullName}
        <ul style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
          <li>No subscription fees </li>
          <li>No ads</li>
        </ul>
        New accounts get {process.env.REACT_APP_NEW_ACCOUNT_CREDITS} credits free that's one hour of streaming music!
        To learn more about our unique pricing <a href='/Pricing' alt='Pricing' target='_blank' rel='noreferrer'>click here</a>
      </div>
    )
  }
  /**
   * Don't show the Welcome screen if accessed by googlebot or none of the content gets indexed because it
   * actually waits long enough to see the BlurDialog.
   * @returns 
   */
  const displayWelcomeScreen = () => {
    if (currentRoom && roomOwner) {
      const { name: roomName, accessType } = currentRoom
      const { handle } = roomOwner
      if (accessType !== ACCESS_TYPES.PUBLIC && !accessToken) {
        const returnPath = requestedMessageId ? `/song/${requestedMessageId}` : `/@${handle}/${roomName}`
        return (<div onClick={() => dispatch(setShowSignIn(false))}>
          <BlurDialog
            content={() => <SignInComponent returnPath={returnPath} content={signInContent} />}
            image={harmonySmall} hideHelp hideTitle close={() => history.replace('/')} closeIcon top={'5vh'} />
        </div>)
      } else if (!startWithPost && showWelcome && !isGooglebot(window.navigator.userAgent)) {
        return (<div onClick={() => dispatch(setShowWelcome(false))}>
          <BlurDialog content={displayIntro} image={harmonySmall} hideHelp closeIcon close={() => dispatch(setShowWelcome(false))} top={veryNarrow ? '5vh' : '10vh'} />
        </div>)
      }
    }
  }

  const displayFullScreen = () => {
    return (
      <div style={{ position: 'relative' }}>
        {displayPostForMode()}
        <div style={{ position: 'sticky', bottom: '2vh', zIndex: '1' }}>
          {displayHarmonizePlayer()}
        </div>

      </div>
    )
  }
  /**
   * The sticky position only works if:
   * - zIndex is set to >0. WIthout this content scrolls over the player.
   * - The height of the container is set larger than the scrolled container. Without this the sticky only stays
   *   through part of the list
   * @returns 
   */
  const displayMain = () => {
    if (currentRoom) {
      if (fullscreen) {
        return displayFullScreen()
      } else {
        return (
          <div style={{ position: 'relative', width: '100%', height: '200%', }}>
            <ErrorLine error={error} />
            {createPost || editingMessageId ? displayCreatePost() :
              (<div><div style={{ position: 'sticky', top: 0, zIndex: '1' }}>
                {displayHarmonizePlayer()}
              </div>
                <div style={{
                  paddingBottom: showChat ? '30vh' : reactorPlay ? '0' : '100px',
                  backgroundColor: primaryColor,
                }}>{displayOnCondition()}</div></div>)}
            {displayWelcomeScreen()}
          </div>
        )
      }
    } else {
      return null
    }
  }

  /**
   * Put the Studio collection at the top -- this gets us back to all songs in the studio.
   * Push Liked with this User's id so that if we switch Studios we don't use this Liked list.
   * @param {*} collections 
   * @param {*} owner 
   */
  const addFixedPlaylists = (collections, owner) => {
    collections.push({ ...STUDIO_COLLECTION, userId })
    if (owner.user === userId) {
      //collections.push({ name: 'Following' })
      collections.push({ ...LIKED_COLLECTION, userId })
    }

  }

  /**
   * Always load collections for the Room owner when initializing the player. 
   * 
   * 
   */
  const fetchRoomCollections = async (owner) => {
    console.log('fetchRoomCollections', owner)
    const { user } = owner
    try {
      const coll = await getCollections(user)
      addFixedPlaylists(coll, owner)
      console.log('fetchRoomCollections', coll)
      dispatch(setCollections(coll))

    } catch (error) {
      console.error(error)
    }
  }

  /**
   * The channel address for a publicAccess room that is being accessed anonymously is:
   * wss://service.harmonize.social/[roomId]=[apikey]
   * 
   * The channel address for every other room  is:
   * wss://service.harmonize.social/[channelName]/[_id]/[b64AccessToken]
   * 
   * where
   * channelName and _id are taken from the room
   * b64AccessToken      is the signed-in User's accessToken, b64 encoded
   * @param {*} room
   */
  const connectOwnerAndSocket = async (room) => {
    const { accessType, channel, _id, name } = room
    console.log(`connectOwnerAndSocket to room ${name} accessType ${accessType}`, room)
    const { name: channelName, user } = channel
    console.log(`connectOwnerAndSocket userId ${userId} room owner ${user}`, room)
    const roomOwner = await getRoomOwner(room)
    if (accessType === ACCESS_TYPES.PUBLIC) {
      console.log(`...connect to public room ${getPublicRoomName(room)}`)
      configureSocket(`/${_id}=${userId ? userId : 0}=${process.env.REACT_APP_API_KEY}`, roomOwner)
    } else if (accessToken) {
      console.log(`...connect to channel /${channelName}/${_id}/[accessToken]`)
      const b64AccessToken = btoa(accessToken)
      configureSocket(`/${channelName}/${_id}/${b64AccessToken}`, roomOwner)
    }
    let updatedRoom = { ...room, roomOwner }
    if (userId !== user) {
      const incrementedRoom = await incrementRoomViews(_id)
      updatedRoom = { ...incrementedRoom, roomOwner }
    }
    dispatch(setCurrentRoom(updatedRoom))
    console.log('...currentRoom is now', updatedRoom)
    /*
    if (accessToken) {
      const thoughtsForRoom = await getThoughtBalanceForRoom(_id, accessToken)
      dispatch(setCurrentAvailableThoughts(thoughtsForRoom))
    }
    */
  }

  const connectError = (exc) => {
    console.error('connectError', exc)
    const { status } = exc
    if (status === 401) {
      history.push('/SignIn')
    } else {
      displayError(exc, setError)
    }
  }

  const connectRoom = async () => {
    setLoading(true)
    setLoadFailed(false)
    setLoaded(false)

    let roomToGet, roomToGetHandle, roomToGetUsername, failed
    if (requestedMessageId) {
      try {
        const requestedMessage = await getMessage(requestedMessageId)
        console.log('requestedMessage', requestedMessage)
        roomToGet = requestedMessage.roomName
        roomToGetUsername = requestedMessage.username
      } catch (error) {
        history.replace('/')
        failed = true
      }
    } else {
      roomToGetHandle = roomHandle
      roomToGet = defaultRoom
        ? defaultRoom
        : requestedRoom
          ? requestedRoom
          : getPublicRoomName()
    }
    if (!failed) {
      console.log(`\n*** connectRoom ${roomToGet} roomHandle ${roomHandle} requestedMessageId ${requestedMessageId}`)
      if (!roomToGet) {
        setError('No valid room was provided')
        setLoading(false)
        setLoadFailed(true)
      } else {
        try {
          const room = await getAndConnectRoom(
            roomToGet,
            roomToGetHandle,
            roomToGetUsername,
            accessToken,
            history,
            dispatch,
            (exc) => connectError(exc)
          )
          if (room) {
            //dispatch(setCurrentRoom(room))
            await connectOwnerAndSocket(room)
            setLoading(false)
            console.log('--------- setLoaded TRUE')
            setLoaded(true)
            setLoadFailed(false)
            dispatch(setWaitingForHost(false))
            dispatch(setPlayListIndex(0))
          } else {
            setLoading(false)
            setLoadFailed(true)
          }

        } catch (error) {
          setLoading(false)
          setLoadFailed(true)
          displayError(error, setError)
        }
      }
    }
  }

  const safeEmit = (type, message) => {
    if (socket) {
      socket.emit(type, message)
    }
  }

  /**
   * 
   * * NOTE * An end of div scroo elvent occurs whenever we go into Fullscreen.
   * 
   * Height factors:
   * See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
   * and
   * scrollHeight: the height of an element's content, including content not visible on the screen due to overflow.
   *  the minimum height the element would require in order to fit all the content in the viewport without using a vertical scrollbar
   * clientHeight: the inner height of an element in pixels. It includes padding but excludes borders, margins, and horizontal scrollbars (if present).
   * scrollTop: the distance from the element's top to its topmost visible content. May be floating point on scaled displays bvut
   *    supposedly never negative
   *
   * So hidden content is scrollHeight-scrollTop: the amount of content above the visible top. clientHeight is the height of the scrolling area.
   * If clientHeight and hidden height are equal then we are at the bottom of current content (it's all scrolled up). If we test for
   * a difference of 100 or less then it means there is still some content showing in the scrolling area. But it could also mean that
   * e.g. clientHeight or scrollTop on the mobile browser is not accurate because of the soft keys at the bottom or the address bar at the top.
   *
   * Hacks: Testing for absolute bottom does not work on actual Android mobile browsers (though in DevTools it works). We throw 100 at it for now.
   * 50 didn't work
   *
   * scrollTop issues:
   *
   * scrollTop is floating point on Android Chrome browser.
   * Suggested to use this: element.scrollHeight - Math.floor(element.scrollTop) === element.clientHeight
   *
   * See https://stackoverflow.com/questions/58972770/javascript-code-is-not-working-latest-chrome-in-android
   *
   * State issues:
   *
   * See https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
   * @param {*} e
   */
  const handleScroll = async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement
    const bottom = scrollHeight - scrollTop - 100 <= clientHeight

    if (!(scrollFetchOffRef.current || endOfMessagesRef.current) && bottom && currentRoom) {
      /* This is also triggered by entering Fullscreen mode */
      console.log(`\n ***> End of div scrollFetchOff ${scrollFetchOffRef.current} endOfMessages ${endOfMessagesRef.current} getMessages ${messagesOffsetRef.current} offset *************`)
      setScrollFetchOff(true)
      try {
        safeEmit('getMessages', { userId, start: messagesOffsetRef.current, count: parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE) })
      } catch (error) {
        console.error('Error getting new messages', error)
      }
      //setScrollFetchOff(false) safeEmit does not block so do not call this until response from Social Service
    }
  }

  /**
   * Called when the requestMessages response from Social Service is received. 
   */
  useEffect(() => {
    console.log(`\n ***>Studio useEffect fullscreen ${fullscreen} requestedMessages ${requestedMessages ? requestedMessages.length : 'null'}`, requestedMessages)
    if (requestedMessages) {
      if (requestedMessages.length) {
        createPlaylist(requestedMessages, !initialized).then(() => {
          initialized = true
          if (requestedMessages.length < parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE)) {
            console.log(`\n*** end of messages, offset ${messagesOffsetRef.current} new block length ${requestedMessages.length}`)
            setEndOfMessages(true)
          }
          setRequestedMessages(null)
        })
      } else {
        setEndOfMessages(true)
        setMessagesOffset(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedMessages])

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  /**
   * This is the only way to do it. All those posts that show a div handling onScroll are
   * not relevant when using Material UI.
   */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      //console.log('remove scroll listener')
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom])

  const handleVisibilityChange = () => {
    //console.log(`visibilityChange ${document.visibilityState}`)
    if (document.visibilityState === 'hidden') {
      console.log('---> Harmonize disconnecting due to visibility change')
      disconnectExisting()
    } else if (currentRoom && !socket) {
      console.log('---> Harmonize Reconnecting due to visibility change')
      connectExisting()
    }
    setVisible(document.visibilityState)
  }
  useEffect(() => {
    if (currentRoom) {
      document.title = `Harmonize | ${currentRoom.name}`
    }
  }, [currentRoom])

  useEffect(() => {
    addCommentToMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentToAdd])

  useEffect(() => {
    deleteCommentFromMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentToDelete])

  useEffect(() => {
    updateMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageToUpdate])

  useEffect(() => {
    deleteMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageToDelete])

  useEffect(() => {
    //console.log(`useEffect visibility ${document.visibilityState}`)
    window.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      //console.log('remove visibilityChange listener')
      window.removeEventListener('visibilitychange', handleVisibilityChange)
      disconnectChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log(`Harmonize useEffect showChat ${showChat}`)
    if (!showChat) {
      leaveChat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat])

  useEffect(() => {
    dispatch(setAutoPlay(false))
    if (requestedMessageId) {
      dispatch(setCurrentCollection())
    }
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * If the user enters a protected Studio without being signed in, connectRoom executes but does not connect to the Social Service.
   * In this case hasAccessToken is false. When the sign in completes, accessToken is updated and that triggers this method again.
   * At that point because hasAccessToken is still false, the connectRoom executes again. This time it does connect
   * to the Social Service, getting the initial messages.
   */
  useEffect(() => {
    console.log(`Harmonize useEffect hasAccessToken ${hasAccessToken} loading ${loading} loaded ${loaded} playListIndex ${playListIndex}`)
    if (!(loaded || loadFailed) || (!hasAccessToken && accessToken)) {
      dispatch(setMessages([]))
      connectRoom()
      if (accessToken) {
        setHasAccessToken(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, playerMode, accessToken])

  // If you disable right click no one can copy text from the posts which isn't good
  //if (process.env.REACT_APP_MODE === 'development') {
  const fullScreenHandle = useFullScreenHandle()
  const fullScreenChange = useCallback((state) => {
    console.log(`fullScreenChange ${state} playerMode ${playerMode}`)
    dispatch(setFullscreen(state))
    if (!state) {
      //dispatch(setPlayerMode(PLAYER_MODES.COMPACT))
      dispatch(setHideOverlay(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenHandle])
  return (<>
    <FullScreen handle={fullScreenHandle}
      onChange={fullScreenChange}
    >
      {displayMain()}
    </FullScreen>
  </>
  )

}

/* This stuff a) is flaky b) does not work at all on iPad so c) it is out
const displayNoNotifications = () => {
  if (showNoNotifications) {
    return (
      <Snackbar
        open={showNoNotifications}
        autoHideDuration={5000}
        onClose={() => { setShowNoNotifications(false) }}
        message={`You will not receive notifications for this studio (${notificationPermission})`}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
      </Snackbar>
    )
  }
}

 
// "Can't find variable Notification" error in both iPad browsers and the deprecated callback method
// broke the page.
 
const requestNotificationPermission = async () => {
  //requesting permission using Notification API
  try {
    //await deleteToken()
    //console.log('maybe deleted existing token')
    const permission = await Notification.requestPermission()
    const { vapidId } = getFirebaseConfig()
    const messaging = getMessaging()
    if (permission === "granted") {

      const token = await getToken(messaging, {
        vapidKey: vapidId
      })

      //We can send token to server
      console.log("Notification Token generated : ", token)
      setShowNoNotifications(true)
      setNotificationPermission(permission)
    } else if (permission === "denied") {
      setShowNoNotifications(true)
      setNotificationPermission(permission)
    }
  } catch (error) {
    console.error('requestNotificationPermission FAILED', error)
    displayError({ message: 'Notifications are not supported in this browser' }, setError)
  }
}
*/
