import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  description: undefined,
  genre: undefined,
  image: null,
  lyrics: undefined,
  mediaIcons: [],
  mp3: null,
  summary: undefined,
  title: undefined
}

const songReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.SONG.DESCRIPTION:
      return {
        ...state,
        description: action.description,
      }
    case REDUX_TYPES.SONG.GENRE:
      return {
        ...state,
        genre: action.genre,
      }
    case REDUX_TYPES.SONG.IMAGE:
      return {
        ...state,
        image: action.image,
      }
    case REDUX_TYPES.SONG.LYRICS:
      return {
        ...state,
        lyrics: action.lyrics,
      }
    case REDUX_TYPES.SONG.MEDIA_ICONS:
      return {
        ...state,
        mediaIcons: action.mediaIcons,
      }
    case REDUX_TYPES.SONG.MP3:
      return {
        ...state,
        mp3: action.mp3,
      }
    case REDUX_TYPES.SONG.SUMMARY:
      return {
        ...state,
        summary: action.summary,
      }
    case REDUX_TYPES.SONG.TITLE:
      return {
        ...state,
        title: action.title,
      }
    case REDUX_TYPES.SONG.RESET:
      return { ...INITIAL_STATE, mediaIcons: [] }
    default:
      return state
  }
}
export default songReducer
