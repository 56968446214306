import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { isVeryNarrow } from 'util/screenUtils'
import { Grid, useMediaQuery } from '@material-ui/core'

import { getSamplerSongs } from 'controllers/HarmonizeController'
import { displayError } from 'util/screenUtils'
import ErrorLine from 'components/ErrorLine'

import { encryptAudioUrl } from 'util/encryptUtils'
import HomeCard from 'components/HomeCard'
import SongCard from './SongCard'
import artist from '../../images/artist.jpg'
import listener from '../../images/listener.jpg'
import musicStudio from '../../images/studio.jpg'
import demo from '../../images/demo.jpg'
import roundBrain from 'images/round-brain.png'
import { setShowAi } from 'redux/actions/harmonizeActions'
import { setShowAiSignIn } from 'redux/actions/helpActions'
import { getStorageAccount } from 'redux/actions/accountActions'

export default function HomeGrid() {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const gridStyles = {
        item: {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            fontSize: veryNarrow ? '0.75em' : '1em', lineHeight: 'normal'
        },
    }
    const account = getStorageAccount()
    const [error, setError] = useState()
    const [songs, setSongs] = useState()
    const dispatch = useDispatch()


    const aiTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard title='AI Infused' image={roundBrain}
                    action={() => { account ? dispatch(setShowAi(true)) : dispatch(setShowAiSignIn(true)) }}
                    description=
                    {`AI is fully integrated into Harmonize. `}
                    summary={`Use it to make your song listings more intelligent`}
                />
            </Grid>
        )
    }

    const artistTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard title='Better Payments' image={artist} link='/About'
                    description=
                    {`Earn with every 12 seconds your song streams. `}
                    summary={`Check your Vault to see what you have earned.
                    Get paid once a month. Click to learn more...`}
                />
            </Grid>
        )
    }

    const demoTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/@cj' image={demo} title='Rock On!' description=
                    {`Click here for a cool demo`} />

            </Grid>
        )
    }
    const listenerTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/Pricing' image={listener} title='No Ads or Monthly Fees' description=
                    {`Pay only for what you hear - no ads or montlhly fees. `}
                    summary={`Artists earn more and listeners pay less. Click to learn more...`} />
            </Grid>
        )
    }

    const studioTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/help/AboutStudios' image={musicStudio} title='Live Chat' description=
                    {`Everyone gets their own music studio - listeners and artists. `}
                    summary={`Studios contain live chat, playlists, and the Reactor - a unique listening experience. Click to learn more...`} />
            </Grid>
        )
    }

    const getSongCard = (allSongs, title, rearrangedTiles, ix) => {

        const songIx = allSongs.findIndex(s => s.name === title)
        if (songIx !== -1) {
            const song = allSongs[songIx]
            rearrangedTiles.push(<Grid key={ix} item xs style={{ display: 'flex', justifyContent: 'center' }}><SongCard song={song} /></Grid>)
            return allSongs.toSpliced(songIx, 1)
        } else {
            return allSongs
        }

    }
    const rearrange = () => {
        if (songs) {
            const rearrangedTiles = []
            rearrangedTiles.push(artistTile(0))
            let updatedSongs = getSongCard(songs, 'Hang Your Feet out the Window', rearrangedTiles, 10)
            rearrangedTiles.push(listenerTile(1))
            rearrangedTiles.push(studioTile(2))
            updatedSongs = getSongCard(updatedSongs, 'Raging Fire', rearrangedTiles, 11)
            rearrangedTiles.push(demoTile(3))
            rearrangedTiles.push(aiTile(4))
            return (
                <Grid container spacing={veryNarrow ? 2 : 3} style={{ width: '100%', paddingTop: '1em', margin: 0 }}  >
                    {rearrangedTiles.map(m => m)}
                    {updatedSongs.map((song, ix) => <Grid key={ix} item xs style={{ display: 'flex', justifyContent: 'center' }}><SongCard song={song} /></Grid>)}
                </Grid>
            )
        }
    }


    const getUrl = async (source, sourceName, rsaKeyPair) => {
        const { pub } = rsaKeyPair
        const url = await encryptAudioUrl(source, sourceName, pub, true)
        return url
    }

    /*
    const buildSongUrls = async (songs) => {
        //console.log('buildSongUrls', songs)

        return Promise.all(
            songs.map(async (song, ix) => {
                return await buildSong(song)

            })
        )
    }

    const buildSongs = async (songs) => {
        try {
            setError('')
            const updatedSongs = await buildSongUrls(songs)
            console.log('updatedSongs', updatedSongs)
            setSongs(updatedSongs)
        } catch (e) {
            displayError(e, setError)
        }
    }
    */

    const displayMain = () => {

        return (
            <div style={{ width: '100%' }}>
                <ErrorLine error={error} />

                {rearrange()}

            </div>
        )

    }

    const randomizeAndSetSongs = (songs) => {
        // eslint-disable-next-line
        const randomSongs = songs.reduce(([a, b]) => (b.push(...a.splice(Math.random() * a.length | 0, 1)), [a, b]), [[...songs], []])[1]
        setSongs(randomSongs)
    }

    useEffect(() => {
        getSamplerSongs(0, 15).then(songs => randomizeAndSetSongs(songs)).catch(error => displayError(error, setError))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return displayMain()
}