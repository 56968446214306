import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import embed from 'images/embed.png'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'


import styles from 'assets/jss/material-kit-react/views/profilePage.js'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Card from 'components/Card/Card'
import VortexHeader from 'components/Header/vortex/VortexHeader'

const useStyles = makeStyles(styles)

export default function PressRelease() {
  const narrow = useMediaQuery('(max-width:400px)')


  const classes = useStyles()


  return (
    <div >
      <div style={{
        height: narrow ? '200vh' : '100vw'
      }}>
        <VortexHeader />
        <div className={classes.container}>
          <GridContainer style={{ justifyContent: 'center' }}>
            <GridItem xs={12} sm={12} md={8}>
              <Card >


                <div
                  className={classes.container}
                  style={{ paddingLeft: '1vw', paddingRight: '1vw' }}
                >
                  <h3>Harmonize AI Revolutionizes Social Media with Integrated Artificial Intelligence</h3>


                  July 5, 2023 - Harmonize AI, a cutting-edge social media platform, is transforming the way users interact with social media through the integration of artificial intelligence (AI). By harnessing the power of a sophisticated multi-database architecture and the Open AI large language model, Harmonize AI is making social media smarter than ever before.

                  <p>With Harmonize AI, every social media post created in the app immediately becomes accessible for interaction with the AI. Users can engage in intelligent chat conversations on a single post, all posts within a room, or even across an entire category. This groundbreaking approach simplifies the creation of press releases, social media posts, summaries, and stories, empowering users to enhance their content with AI-driven insights.</p>

                  <p>Unlike other social media platforms, Harmonize AI's business model ensures that revenue is driven directly to content creators. With Harmonize AI, there is no minimum requirement of followers to start earning revenue. This innovative platform shares revenue with both the room owner and content creators, providing a fair and inclusive opportunity for all users to monetize their content.</p>

                  <p>Harmonize AI is revolutionizing the social media landscape by seamlessly integrating artificial intelligence into every aspect of the user experience. With its intelligent chat capabilities and revenue-sharing model, Harmonize AI empowers content creators to thrive in the digital realm. Join the Harmonize AI community today and unlock the full potential of social media with the power of AI.</p>

                  ###

                  About Harmonize AI:
                  Harmonize AI is a groundbreaking social media platform that integrates artificial intelligence into every post and conversation.
                  By leveraging a sophisticated multi-database architecture and the Open AI large language model,
                  Harmonize AI enables users to engage in intelligent chat conversations and enhances the creation of press releases,
                  social media posts, summaries, and stories.
                  With a unique revenue-sharing model, Harmonize AI drives revenue directly to content creators,
                  providing a fair and inclusive platform for all users to monetize their content.
                  Join the Harmonize AI community today and experience the future of social media.


                </div>

              </Card>

            </GridItem>
          </GridContainer>
        </div>


        <Footer position={'fixed'} />
      </div>

    </div>
  )
}
