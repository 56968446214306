import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Footer from 'components/Footer/Footer.js'
import styles from 'styles/pricingPage'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { getStorageAccount } from 'redux/actions/accountActions'
import { Container } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

const useStyles = makeStyles(styles)

export default function AboutStudios() {
  const account = getStorageAccount()
  const classes = useStyles()
  const narrow = useMediaQuery('(max-width:400px)')
  const containerClasses = narrow
    ? classNames(classes.main)
    : classNames(classes.main, classes.mainRaised)
  useEffect(() => {
    document.title = 'About Studios'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>
          <h4 style={{ color: 'black', textAlign: 'center', borderBottom: '1px solid black' }}>Studios</h4>
          <h4>Harmonize Studios</h4>
          Everything you hear in Harmonize is contained in a <i>Studio</i>.
          <h4>Home Studio</h4>
          Everyone -- both listeners and artists -- has
          a <b>home</b> studio. Here is where you see your Liked songs, as well as any playlists you create.
          Your home studio is what other members see when they access Harmonize through your handle. Every
          registered Harmonize member can access any home studio.
          <h4>Private Studio</h4>
          Any member who uploads a song automatically becomes an artist with a <b>private</b> studio. This is
          where artists create the song posts that are at the heart of Harmonize. Access to a private
          studio is limited to the artist who created it.
          <h4>Studio Owners Control the Studio</h4>
          The member who creates a home or private studio is considered the "owner".
          The owner controls content in that studio. For home studios, this means the owner can delete
          comments on songs the owner created. The owner can also delete all of the chat in the live chat
          for that studio.
          <h4>Studio Live Chat</h4>
          Every home studio has its own live chat area. Members currently in a home studio can chat with
          other members in the studio, and share the songs they are listening to in that studio.
          <h4>Studio Reactor</h4>
          The <b>Reactor</b> is a unique shared listening experience. A listener may open the Reactor
          on any song. Then as the song plays, the listener can select an emoji that matches their
          feeling about the song at the moment it is playing. These reactions occur in real-time,
          so that any other listener in the Reactor for that song sees reactions instantly. Later,
          any other listener who opens the Reactor sees the previously-recorded reactions. By clicking
          on a reaction, the listener is taken immediately to that point in the song.
        </Container>
      </div>
      <Footer />
    </div >
  )
}
